import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { REFRESH_TOKEN, TOKEN } from '../constants';
import { useModalToggle } from '../hooks';
import BasicUserInfo from '../profile/basic-user-info';
import DeleteAccountModal from '../profile/delete-user-modal';
import { DELETE_ACCOUNT, useMe } from '../services/user.service';
import LoadingPage from './loading';
import DiscountUserInfo from '../profile/discount-user-info';

export default function Account() {
  const { open, close, props } = useModalToggle();
  const { data: me, loading } = useMe();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [deleteAccount] = useMutation(DELETE_ACCOUNT);
  if (loading) {
    return <LoadingPage />;
  }

  async function handleDelete() {
    if (!me || !me.id) return;
    if (!password || password === '') {
      return;
    }
    try {
      const { data } = await deleteAccount({
        variables: {
          password,
        },
      });
      if (data.deleteAccount.success) {
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        navigate('/onboarding');
      } else {
        alert("Couldn't delete account");
      }
    } catch (e) {
      alert('Error');
      console.log(e);
    }
    close();
  }

  return (
    <>
      <DeleteAccountModal
        action={handleDelete}
        open={props.visible}
        close={() => {
          setPassword('');
          close();
        }}
        password={password}
        setPassword={setPassword}
      />
      <BasicUserInfo />
      <div className="px-8">
        <button
          onClick={open}
          type="button"
          className="inline-flex w-full items-center justify-center self-center rounded-md border border-transparent bg-red-600 px-6 py-3 text-center text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          Delete Account
        </button>
      </div>
    </>
  );
}
