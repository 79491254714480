import { faArrowLeft, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { classNames } from '../helpers';
import { track } from '@amplitude/analytics-browser';

interface Props {
  title?: string;
  shadow?: boolean;
  url?: string;
  kitchen?: boolean;
  orderItems?: number;
  cartTotal?: number;
}

export default function TopNavigation({
  title,
  url,
  shadow,
  kitchen,
  orderItems,
}: Props) {
  const navigate = useNavigate();
  const navigationURL = url ? () => navigate(url) : () => navigate(-1);
  return (
    <div className="sticky top-0 z-20">
      <div
        key={'navigation'}
        className={classNames(
          shadow ? 'shadow-sm' : undefined,
          'flex justify-between border-b bg-white py-5 px-6'
        )}
      >
        <button onClick={navigationURL}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            size="lg"
            className="text-gray-400"
          />
        </button>
        <div className="text-base font-medium leading-6 text-gray-900">
          {title ?? 'Order Food'}
        </div>
        {kitchen ? (
          <div
            className={'flex flex-row items-center text-gray-500'}
            onClick={() => {
              track('cart_clicked', {
                cart_quantity: orderItems,
                cart_dollar_amount: 0,
              });
              navigate('/order-summary');
            }}
          >
            <FontAwesomeIcon className={'mr-2 text-sm'} icon={faShoppingCart} />
            <div className="text-xs">{orderItems ? orderItems : ''}</div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}
