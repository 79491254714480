import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import loadingAnimation from '../img/loading.json';
import logo from '../img/logo-black-square.png';
import ProgressBar from '@ramonak/react-progress-bar';

interface Props {
  loading: boolean;
  fridgeDatesLoading: boolean;
}

export default function LoadingProgress({
  loading,
  fridgeDatesLoading,
}: Props) {
  const [completed, setCompleted] = useState(0);
  useEffect(() => {
    if (!loading && !fridgeDatesLoading) {
      setCompleted(100);
    }
  }, [loading, fridgeDatesLoading]);

  const loadingText = [
    'Populating dates',
    'Removing unavailable days',
    'Applying cutoff times',
    'Checking inventory',
    'Populating products',
    'Populating featured items',
    'Populating promotions',
    'Checking for discounts',
    'Assigning categories',
    'Rendering images',
    'Preparing your menu',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (completed < 95) {
        setCompleted((prev) => (prev < 95 ? prev + 1 : 95));
      }
    }, 220);

    return () => clearInterval(interval);
  }, []);

  function getTextCase() {
    if (completed <= 9) {
      return 'Populating dates';
    } else if (completed <= 19) {
      return 'Removing unavailable days';
    } else if (completed <= 28) {
      return 'Applying cutoff times';
    } else if (completed <= 36) {
      return 'Checking inventory';
    } else if (completed <= 46) {
      return 'Populating products';
    } else if (completed <= 55) {
      return 'Populating featured items';
    } else if (completed <= 64) {
      return 'Populating promotions';
    } else if (completed <= 73) {
      return 'Checking for discounts';
    } else if (completed <= 82) {
      return 'Assigning categories';
    } else if (completed <= 95) {
      return 'Rendering images';
    } else {
      return 'Preparing your menu';
    }
  }
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-black py-12 sm:px-6 lg:px-8">
      <img src={logo} className="h-48" alt="cubbi" />
      <div className="mb-24 sm:mx-auto sm:w-full sm:max-w-md">
        <Lottie
          animationData={loadingAnimation}
          style={{ height: 115, marginTop: -32, zIndex: 8 }}
          loop
        />
        <div className="py-4" />
        <div className="pr-4 text-lg font-medium text-white">
          {getTextCase()}
        </div>
        <ProgressBar
          completed={completed}
          isLabelVisible={false}
          bgColor="#4338ca"
          labelAlignment="center"
        />
      </div>
    </div>
  );
}
