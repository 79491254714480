import { useMutation } from '@apollo/client';
import {
  faExternalLinkAlt,
  faInfoCircle,
  faLockOpen,
  faUnlockAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getNodeDataFromEdges } from '../helpers';
import TopNavigation from '../navigation/top-navigation';
import {
  FridgeFilterStatePersistentStorageItem,
  FRIDGE_FILTER_STATE_KEY,
} from '../persistent-storage/fridge-filter-state.persistent-storage';
import { usePersistentStorageItem } from '../persistent-storage/hooks';
import {
  FridgeOrderStatePersistentStorageItem,
  FRIDGE_ORDER_STATE_KEY,
} from '../persistent-storage/order-fridge-state.persistent-storage';
import RescheduleSelectOrderModal from '../reschedule-meals/reschedule-select-order-modal';
import { OPEN_FRIDGE_ALL } from '../services/fridges.service';
import { useMe } from '../services/user.service';
import { Booking, OrderSet } from '../types';
import FridgeBanner from './fridge-banner';
import FridgeOrderView from './fridge-order-display';
import FridgeOrderItem from './fridge-order-item';
import MealAlert from './meal-alert';
import OpenFridgeModal from './open-fridge-modal';
import SelectedFridgeItem from '../fridges/selected-fridge-item';
import FridgeOrderFridge from '../fridges/fridge-order-fridge';
import { track } from '@amplitude/analytics-browser';

export default function FridgeOrder() {
  const fridgeOrderState = new FridgeOrderStatePersistentStorageItem(
    FRIDGE_ORDER_STATE_KEY
  );
  const fridgeFilterState = new FridgeFilterStatePersistentStorageItem(
    FRIDGE_FILTER_STATE_KEY
  );
  const [openFridge] = useMutation<{ success: Boolean }>(OPEN_FRIDGE_ALL);
  const { data: me } = useMe();
  const [fridgeOrder] = usePersistentStorageItem(fridgeOrderState, []);
  const [fridgeFilter] = usePersistentStorageItem(fridgeFilterState, '');
  const [orderModal, setOrderModal] = useState(false);
  const [filteredOrder, setFilteredOrder] = useState<Booking[]>();
  const [past, setPast] = useState(false);
  const [present, setPresent] = useState(false);
  const [orderWindow, setOrderWindow] = useState(false);
  const [orderWindowWeekend, setOrderWindowWeekend] = useState(false);
  const [openFridgeModal, setOpenFridgeModal] = useState(false);
  const [confirmedOpen, setConfirmedOpen] = useState(false);
  const [showMealAlert, setShowMealAlert] = useState(false);

  const today = moment();
  const date = moment(
    filteredOrder ? (filteredOrder[0] ? filteredOrder[0].startAt : null) : null
  );
  const nextDate = moment().add(1, 'days');
  const prevDate = moment().add(-1, 'days');
  date.set({ hour: 12, minute: 0, second: 0 });

  let filteredOrderIds = filteredOrder
    ? filteredOrder.map(({ orderId }) => orderId)
    : undefined;

  let unique = [...new Set(filteredOrderIds)];
  let stringResults = unique.map((e) => (e ? e.toString() : ''));

  let bookingIds = filteredOrder?.map((e) => e.id);

  const navigate = useNavigate();

  //Takes the entire list of possible bookings and filters them down to the name of the fridge + date, so that it only has those bookings
  useEffect(() => {
    if (fridgeOrder && fridgeFilter) {
      const names = fridgeOrder?.map(() => fridgeFilter);
      const result = fridgeOrder.filter((e, index) =>
        names.includes(e.cubbi.fridge.name + e.startAt.substring(0, 10), index)
      );
      setFilteredOrder(result);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Calculations used to check the current date.
  useEffect(() => {
    if (today && filteredOrder && filteredOrder[0]) {
      const current = moment(filteredOrder[0].startAt.substring(0, 10));
      if (
        today.toISOString(true).substring(0, 10) ===
        current.toISOString(true).substring(0, 10)
      ) {
        setPresent(true);
      } else if (
        current.toISOString(true).substring(0, 10) <=
        today.toISOString(true).substring(0, 10)
      ) {
        setPast(true);
      } else {
        setPast(false);
        setPresent(false);
      }
    }
    if (filteredOrder && filteredOrder[0]) {
      //set the order window conditionally, first check if it's before 9AM the day after,
      //OR check if they have weekend fridge access,
      //it was ordered on a friday and it's less than threeDaysAhead at 9AM
      const threeDaysAhead = moment(filteredOrder[0].startAt)
        .add(3, 'days')
        .toISOString(true)
        .substring(0, 10);
      if (
        parseInt(today.toISOString(true).substring(11, 13)) < 9 &&
        filteredOrder[0].startAt.substring(0, 10) ===
          prevDate.toISOString(true).substring(0, 10)
      ) {
        setOrderWindow(true);
      } else {
        setOrderWindow(false);
      }
      if (
        filteredOrder[0].cubbi.fridge.allowWeekendAccess &&
        moment(filteredOrder[0].startAt).day() === 5 &&
        (today.toISOString(true).substring(0, 10) < threeDaysAhead ||
          (today.toISOString(true).substring(0, 10) === threeDaysAhead &&
            parseInt(today.toISOString(true).substring(11, 13)) < 9))
      ) {
        setOrderWindowWeekend(true);
      } else {
        setOrderWindowWeekend(false);
      }
    }
  }, [today, filteredOrder, prevDate]);

  useEffect(() => {
    if (confirmedOpen) {
      handleOpenFridge();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedOpen]);

  function checkIfCancelled(orderSet: OrderSet[]) {
    const flatten = orderSet.flat();
    const cancelled = flatten.some((e) => e.refundId !== null);
    return cancelled;
  }

  async function handleOpenFridge() {
    if (filteredOrder && filteredOrder[0] && me) {
      const userEmail = me.email;
      const fridgeId = filteredOrder[0].cubbi.fridge.id;
      if (userEmail) {
        try {
          const { data } = await openFridge({
            variables: {
              input: {
                user: userEmail,
                fridgeId: fridgeId,
              },
            },
          });
          if (data) {
            setShowMealAlert(true);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
    setConfirmedOpen(false);
  }

  const cancelledTotal = filteredOrder
    ? filteredOrder.filter((e) => e.cancelledAt !== null)
    : [];

  const cancelledLength = cancelledTotal.length;

  const delivered = filteredOrder
    ? filteredOrder.filter((e) => e.deliveredAt !== null)
    : [];

  return (
    <div className="min-h-screen">
      <TopNavigation title="Delivery Details" url="/upcoming" />
      {/*if Order exists display its delivery date */}
      {filteredOrder && filteredOrder[0] && (
        <>
          <div className="pt-5"></div>
          <div className="px-4 pb-3">
            <div className="flex inline-flex text-gray-900">
              <div className="text-lg font-medium text-gray-900">
                {moment(filteredOrder[0].startAt).format('ddd, MMM D')} Delivery
              </div>
              <div className="flex items-center pl-2 text-gray-500">
                @ 11:30 AM
              </div>
            </div>
          </div>
        </>
      )}
      {/*order exists and has a delivered order in it and it is today */}
      {filteredOrder && delivered.length > 0 && present && (
        <div className="px-4 pb-5">
          <div className="flex">
            <dt className="flex items-center rounded-lg bg-green-100 px-2 text-center text-xs leading-4 text-green-800">
              Delivered
            </dt>
            <div className="pr-2"></div>
            {filteredOrder[0] &&
            filteredOrder[0].prestockBookings &&
            filteredOrder[0].prestockBookings.edges.length > 0 ? (
              <dd className="flex items-center rounded-lg bg-gray-100 px-2 text-center text-xs leading-4 text-gray-800">
                Collect by{' '}
                {moment(filteredOrder[0].endAt).format('ddd D, h:mmA')}
              </dd>
            ) : (
              <dd className="flex items-center rounded-lg bg-gray-100 px-2 text-center text-xs leading-4 text-gray-800">
                Collect by {moment(nextDate).format('ddd D')}, 9:00AM
              </dd>
            )}
          </div>
        </div>
      )}
      {/*If the order exists, it is not marked as deilvered and it is the day of Delivery and there are 0 cancellations */}
      {filteredOrder &&
        delivered.length === 0 &&
        present &&
        cancelledLength === 0 && (
          <div className="px-4 pb-5">
            <div className="flex">
              <dt className="flex items-center rounded-lg bg-blue-100 px-2 text-xs leading-4 text-blue-800">
                Preparing Finalized Delivery
              </dt>
            </div>
          </div>
        )}
      {/*If the order exists and it is the day of delivery and not all orders are cancelled, but some are */}
      {filteredOrder &&
        delivered.length === 0 &&
        present &&
        cancelledLength > 0 &&
        cancelledLength !== filteredOrder.length && (
          <div className="px-4 pb-5">
            <div className="flex flex-col">
              <dt className="flex w-[218px] items-center rounded-lg bg-blue-100 px-2 text-xs leading-4 text-blue-800">
                Preparing Finalized Delivery
              </dt>
            </div>
          </div>
        )}
      {/*Delivery for orderWindow next day */}
      {filteredOrder &&
        filteredOrder[0] &&
        delivered.length > 0 &&
        past &&
        orderWindow && (
          <div className="px-4 pb-5">
            <div className="flex">
              <dt className="flex items-center rounded-lg bg-green-100 px-2 text-center text-xs leading-4 text-green-800">
                Delivered
              </dt>
              <div className="pr-2"></div>
              <dd className="flex items-center rounded-lg bg-gray-100 px-2 text-center text-xs leading-4 text-gray-800">
                Collect by{' '}
                {moment(filteredOrder[0].startAt)
                  .add(1, 'days')
                  .format('ddd D')}
                , 9:00AM
              </dd>
            </div>
          </div>
        )}
      {/*Delivery window for orderWindow with orderWindowWeekend conditions met */}
      {filteredOrder &&
        filteredOrder[0] &&
        delivered.length > 0 &&
        past &&
        orderWindowWeekend && (
          <div className="px-4 pb-5">
            <div className="flex">
              <dt className="flex items-center rounded-lg bg-green-100 px-2 text-center text-xs leading-4 text-green-800">
                Delivered
              </dt>
              <div className="pr-2"></div>
              <dd className="flex items-center rounded-lg bg-gray-100 px-2 text-center text-xs leading-4 text-gray-800">
                Collect by{' '}
                {moment(filteredOrder[0].startAt)
                  .add(3, 'days')
                  .format('ddd D')}
                , 9:00AM
              </dd>
            </div>
          </div>
        )}
      {/*Check if the order exists and there was no cancellations and it's not in the orderWindow or orderWindowWeekend */}
      {filteredOrder &&
        filteredOrder[0] &&
        !orderWindow &&
        !orderWindowWeekend &&
        past &&
        cancelledLength === 0 && (
          <div className="px-4 pb-5">
            <div className="flex">
              <dt className="flex items-center rounded-lg bg-red-100 px-2 text-center text-xs leading-4 text-red-800">
                Pickup window closed
              </dt>
            </div>
          </div>
        )}
      {/*Check if order is in the future with no cancellations */}
      {!past && !present && cancelledLength === 0 && (
        <div className="px-4 pb-5">
          <div className="flex">
            <dt className="flex items-center rounded-lg bg-gray-100 px-2 text-center text-xs leading-4 text-gray-800">
              Booked
            </dt>
          </div>
        </div>
      )}
      {/*Check if order is in the future with cancellations, but not all
      second condition says if an order exists and it's in the past and not in the orderWindow or orderWindowWeekend and not all cancelled */}
      {filteredOrder &&
      cancelledLength > 0 &&
      cancelledLength < filteredOrder?.length &&
      !present &&
      !past ? (
        <div className="flex flex-col px-4">
          <p className="flex w-20 items-center rounded-lg bg-gray-100 px-2 text-center text-xs leading-4 text-gray-800">
            Booked
          </p>
        </div>
      ) : (
        filteredOrder &&
        cancelledLength > 0 &&
        cancelledLength < filteredOrder?.length &&
        !present &&
        past &&
        !orderWindow &&
        !orderWindowWeekend && (
          <div className="flex flex-col px-4">
            <p className="flex w-[186px] items-center rounded-lg bg-red-100 px-2 text-center text-xs leading-4 text-red-800">
              Pickup Window Closed
            </p>
          </div>
        )
      )}
      {/*number of orders cancelled to that fridge for that day, but not all */}
      {filteredOrder &&
        cancelledLength > 0 &&
        cancelledLength < filteredOrder.length && (
          <div className="flex flex-col px-4">
            <p className="py-2 text-red-500">
              {cancelledLength > 1
                ? `${cancelledLength} Orders have been cancelled`
                : `1 Order has been cancelled.`}
            </p>
          </div>
        )}
      {/*All orders cancelled for that day to that fridge */}
      {filteredOrder && cancelledLength === filteredOrder.length && (
        <div className="flex flex-col px-4">
          <p className="flex w-[86px] items-center rounded-lg bg-gray-100 px-2 text-center text-xs text-gray-800">
            Cancelled
          </p>
          <p className="py-2 text-red-500">
            All Orders have been cancelled for this day.
          </p>
        </div>
      )}
      <div>
        <OpenFridgeModal
          open={openFridgeModal}
          setOpen={setOpenFridgeModal}
          setOpenFridge={setConfirmedOpen}
        />
        <MealAlert open={showMealAlert} setOpen={setShowMealAlert} />
      </div>
      <div className="mx-4 pb-8 pt-1">
        <div className="rounded-lg bg-blue-50 py-4 px-4 text-blue-800">
          <div className="inline-flex">
            <FontAwesomeIcon icon={faInfoCircle} className="text-blue-400" />
            <p className="pl-4">Unable to pickup delivery items?</p>
          </div>
          <br />
          <div className="inline-flex py-4 px-8 text-blue-700">
            <a
              href="https://app.cub.bi/#/support:email"
              className="pr-4"
              target="__blank"
            >
              Email us
            </a>
            <a target="__blank" href="https://app.cub.bi/#/support:phone">
              Call now
            </a>
            {filteredOrder && delivered.length < filteredOrder?.length ? (
              <div
                className="pl-4"
                onClick={() =>
                  navigate('/reschedule', { state: { bookingIds } })
                }
              >
                Reschedule
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
      {filteredOrder && filteredOrder[0] && (
        <FridgeOrderFridge fridge={filteredOrder[0].cubbi.fridge} />
      )}
      <div className="px-4 pb-3 pt-4 text-gray-500">Delivery Items</div>
      {filteredOrder &&
        filteredOrder.map((order) => {
          const bookingItems = order.bookingItems;
          const orderSetEdges =
            order.orderSet && getNodeDataFromEdges(order.orderSet.edges);
          const cancelled = checkIfCancelled(orderSetEdges);
          return (
            <div className="px-4">
              <div className="">
                <div
                  className="flex inline-flex items-center py-2 text-indigo-700"
                  onClick={() => {
                    track('order_details_clicked');
                    navigate(`/order-details/${order.orderId}`);
                  }}
                >
                  Order #{order.orderId}
                  <div className="pr-4"> </div>
                  <FontAwesomeIcon
                    className="text-gray-400"
                    icon={faExternalLinkAlt}
                  />
                </div>
                {cancelled && (
                  <div className="-mt-2 px-4 text-sm text-red-500">
                    Items from Order #{order.orderId} have been refunded.
                  </div>
                )}
                {bookingItems.map((items) => {
                  return (
                    <>
                      <FridgeOrderItem
                        item={items}
                        cancelled={cancelled}
                        orderId={order.orderId ? order.orderId : 0}
                      />
                    </>
                  );
                })}
              </div>
            </div>
          );
        })}
      <div className="py-16" />
      {/*Display button if it's not all fully cancelled, the order exists and its past 11:00AM and its the day of delivery */}
      {filteredOrder &&
        (parseInt(today.toISOString(true).substring(11, 13)) >= 11 ||
          delivered.length > 0) &&
        present &&
        cancelledLength !== filteredOrder.length && (
          <div className="absolute sticky bottom-0 z-20 w-full bg-indigo-700 py-8">
            <div className="flex flex-row justify-between px-4">
              <div className="flex flex-col text-lg text-white">
                Collect Items
                <div className="text-md text-indigo-300">
                  {'Enjoy your meal!'}
                </div>
              </div>
              <div className="flex justify-end px-2 text-indigo-600">
                <button
                  onClick={() => setOpenFridgeModal(true)}
                  type="button"
                  className="inline-flex w-full w-[122px] items-center justify-center rounded-md border border-transparent bg-[#EEF2FF] px-2 py-3 text-sm font-medium text-indigo-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <FontAwesomeIcon
                    icon={faUnlockAlt}
                    className="mr-3 h-5 w-5 text-base font-medium leading-6 text-indigo-500"
                    aria-hidden="true"
                  />
                  Open
                </button>
              </div>
            </div>
          </div>
        )}
      {/*Display the button if it's past, not all cancelled the order exists, and its in the orderWindow or orderWindowWeekend */}
      {filteredOrder &&
        past &&
        (orderWindow || orderWindowWeekend) &&
        cancelledLength !== filteredOrder.length && (
          <div className="absolute sticky bottom-0 z-20 w-full bg-indigo-700 py-8">
            <div className="flex flex-row justify-between px-4">
              <div className="flex flex-col text-lg text-white">
                Collect Items
                <div className="text-md text-indigo-300">
                  {'Enjoy your meal!'}
                </div>
              </div>
              <div className="flex justify-end px-2 text-indigo-600">
                <button
                  onClick={() => setOpenFridgeModal(true)}
                  type="button"
                  className="inline-flex w-full w-[122px] items-center justify-center rounded-md border border-transparent bg-[#EEF2FF] px-2 py-3 text-sm font-medium text-indigo-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <FontAwesomeIcon
                    icon={faUnlockAlt}
                    className="mr-3 h-5 w-5 text-base font-medium leading-6 text-indigo-500"
                    aria-hidden="true"
                  />
                  Open
                </button>
              </div>
            </div>
          </div>
        )}
      <RescheduleSelectOrderModal
        open={orderModal}
        setOpen={setOrderModal}
        items={stringResults ? stringResults : ['']}
      />
    </div>
  );
}
