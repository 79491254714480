export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refresh_token';
export const FRIDGE = 'fridge';
export const BOOKING_DAYS = 'booking_days';

export enum NotificationType {
  Success,
  Error,
  Warning,
  Info,
}

export enum SelectionType {
  A_0 = 'A_0',
  A_1 = 'A_1',
  A_2 = 'A_2',
  A_3 = 'A_3',
}

export enum Route {
  Login = 'Login',
  Kitchen = 'Kitchen',
  History = 'History',
  Map = 'Map',
  Account = 'Account',
  Settings = 'Settings',
  Logout = 'Logout',
  Register = 'Register',
  Loading = 'Loading',
  Fridges = 'Fridges',
  Checkout = 'Checkout',
  Orders = 'Orders',
  OrderSuccess = 'OrderSuccess',
  Upcoming = 'Upcoming',
  Receipt = 'Receipt',
  Onboarding = 'Onboarding',
  OrderDetails = 'OrderDetails',
  Coupons = 'Coupons',
  Subsidies = 'Subsidies',
}

export enum ErrorCode {
  Unique = 'unique',
  PasswordShort = 'password_too_short',
  PasswordCommon = 'password_too_common',
  PasswordNumeric = 'password_entirely_numeric',
}

export enum AppType {
  Cubbi = 'A_1',
  LunchLocker = 'A_2',
}

export enum PhoneErrorCodes {
  AleadyExistsVerified = 'verified_unique',
  AlreadyExistsUnverified = 'not_verified_unique',
}

export enum TokenAuthError {
  InvalidCredentials = 'invalid_credentials',
}

export enum DiscountKind {
  Coupon = 'A_1',
  Subsidy = 'A_2',
}

export enum RenewableType {
  PerWeek = 'A_1',
  PerMonth = 'A_2',
}
export enum RenewableMonthDay {
  FirstDay = 'A_1',
  LastDay = 'A_2',
}

export enum DiscountTeam {
  CS = 'A_1',
  Marketing = 'A_2',
  TeamOrder = 'A_3',
  Finance = 'A_4',
  Operations = 'A_5',
  SalesCubbi = 'A_6',
  SalesBuisness = 'A_7',
  TeamOrderApp = 'A_8',
}

export enum DiscountType {
  PercentDiscount = 'A_1',
  FixedDiscount = 'A_2',
  FixedCost = 'A_3',
  SingleCost = 'A_4',
  XItemsForYDollars = 'A_6',
  BuyXItemsGetYFree = 'A_7',
  XItemsForPriceOfYItems = 'A_8',
}
