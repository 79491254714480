import 'animate.css';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-spring-bottom-sheet/dist/style.css';
import { cssTransition, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n/config';
import './index.css';
import AuthProvider from './providers/auth-provider';
import GraphQLProvider from './providers/graphql-provider';
import SmartlookProvider from './providers/smartlook-provider';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as amplitude from '@amplitude/analytics-browser';
import DiscountProvider from './providers/discount-provider';

declare global {
  interface Window {
    cordova: any;
  }
}

window.cordova = window.cordova || false;
const amplitudeApiKey = 'e1a34862c8dae260c4a02ead71c18724';

const bounce = cssTransition({
  enter: 'animate__animated animate__fadeInUp animate__faster',
  exit: 'animate__animated animate__fadeOutDown animate__faster',
});

const startApp = ReactDOM.render(
  <React.StrictMode>
    <GraphQLProvider>
      <AuthProvider>
        <DiscountProvider>
          <SmartlookProvider>
            <Routes />
            <ToastContainer
              hideProgressBar={true}
              transition={bounce}
              className="transition ease-in-out"
              newestOnTop={true}
              limit={3}
            />
          </SmartlookProvider>
        </DiscountProvider>
      </AuthProvider>
    </GraphQLProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

if (!window.cordova) {
  amplitude.init(amplitudeApiKey);
  // @ts-ignore
  startApp();
} else {
  // @ts-ignore
  document.addEventListener('deviceready', startApp, false);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
