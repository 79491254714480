import { RefObject, useState, useRef } from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Category, ProductObj, Product } from '../types';
import { Moment } from 'moment';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';
import SearchCategoryDisplay from '../kitchen/search-category-display';
import { Dispatch, SetStateAction } from 'react';
import KitchenDropdown from './kitchen-dropdown';

interface Props {
  categoriesWithRef: CategoryRefType[] | undefined;
  handleOpenProductModal: (product: Product, category: Category) => void;
  addItemToOrder: (product: ProductObj) => void;
  currentDate: Moment;
  showUnavailable: boolean;
  orderItems: OrderState[];
  setSearchKitchen: Dispatch<SetStateAction<boolean>>;
}

export type CategoryRefType = Category & {
  ref: RefObject<HTMLDivElement>;
  icon: IconDefinition;
};

export default function KitchenSearch({
  categoriesWithRef,
  currentDate,
  orderItems,
  addItemToOrder,
  handleOpenProductModal,
  showUnavailable,
  setSearchKitchen,
}: Props) {
  const [searchValue, setSearchValue] = useState<string>('');

  const filterItems = ['Dish', 'Kitchen'];
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(
    'Dish'
  );
  const ref = useRef<HTMLInputElement>(null);

  function handleClick() {
    if (ref.current) {
      ref.current.focus();
    }
    setSearchValue('');
  }
  return (
    <div>
      <div className="sticky top-0 z-20 border-b bg-white">
        <div className="flex h-[62px] w-screen items-center px-2">
          <input
            className={
              'h-[42px] w-full rounded-l-md rounded-r-none border-l border-t border-b border-r-0 px-4 text-gray-500 outline-none outline-0'
            }
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
            type="kitchen"
            value={searchValue}
            autoComplete="kitchen"
            autoFocus={true}
            ref={ref}
          ></input>
          {searchValue && searchValue.length > 0 && (
            <>
              <div onClick={() => handleClick()}>
                <div className="z-20 -ml-6 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-gray-400 outline-none outline-0">
                  <div className="-mt-[2px] text-xs text-white">x</div>
                </div>
              </div>
            </>
          )}

          <div className="flex h-[42px] items-center border-t border-b">
            <div className="h-[20px] border-l"> </div>
          </div>
          <KitchenDropdown
            items={filterItems}
            title={selectedFilter ? selectedFilter : 'Filter'}
            functionToSend={setSelectedFilter}
            changed={selectedFilter !== 'dishes' ? true : false}
            width="w-32"
            widthOfDropdown="w-32"
            height="h-fit"
          />

          <div
            className="px-4 text-indigo-500"
            onClick={() => setSearchKitchen(false)}
          >
            Cancel
          </div>
        </div>
      </div>
      <div className="min-h-full bg-gray-50 ">
        {searchValue &&
          categoriesWithRef &&
          categoriesWithRef
            .sort(({ position: a }, { position: b }) => a - b)
            .map((category, index) => {
              return (
                <SearchCategoryDisplay
                  key={index}
                  category={category}
                  currentDate={currentDate}
                  addItemToOrder={addItemToOrder}
                  handleOpenProductModal={(product) =>
                    handleOpenProductModal(product, category)
                  }
                  showUnavailable={showUnavailable}
                  orderItems={orderItems}
                  searchValue={searchValue}
                  searchType={selectedFilter}
                />
              );
            })}
      </div>
    </div>
  );
}
