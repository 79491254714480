import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faCalendarWeek } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Calendar, Day, utils } from 'react-modern-calendar-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../calendarconfig/Calendar.css';
import { myCustomLocale } from '../calendarconfig/calendarlocale';
import CancelOrderModal from '../components/cancel-order-modal';
import DateSelectFooter from '../components/date-select-footer';
import Notification from '../components/notification';
import { BOOKING_DAYS, NotificationType } from '../constants';
import ModifiedFridgeItem from '../fridges/modififed-fridge-item';
import { disabledDays, handleMaxDate } from '../helpers';
import TopNavigation from '../navigation/top-navigation';
import {
  FridgeStatePersistentStorageItem,
  FRIDGE_STATE_KEY,
} from '../persistent-storage/fridge-state.persistent-storage';
import {
  OrderStatePersistentStorageItem,
  ORDER_STATE_KEY,
} from '../persistent-storage/order-state.persistent-storage';
import PersistentStorage from '../persistent-storage/persistent-storage';
import { useAvailableFridgeDates } from '../services/fridges.service';
import { useMe } from '../services/user.service';
import FridgeWalkthroughSteps from '../signup/onboardingModals/fridges-walkthrough-steps';
import ErrorPage from './error';
import DateSelectSkeleton from '../loading-skeletons/date-select-skeleton';
import { track } from '@amplitude/analytics-browser';

export default function DateSelect() {
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [removeOrder, setRemoveOrder] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Day[]>();
  const [totalDays, setTotalDays] = useState(0);
  const currentYear = utils('en').getToday().year;
  const navigate = useNavigate();
  const dayToday = utils('en').getToday();
  const { data: me, loading, refetch } = useMe();
  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const orderState = new OrderStatePersistentStorageItem(ORDER_STATE_KEY);
  const { state } = useLocation();

  const currentDates = localStorage.getItem(BOOKING_DAYS);
  const fridge = PersistentStorage.get(selectedFridgeState);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && removeOrder === true) {
      localStorage.removeItem(BOOKING_DAYS);
      PersistentStorage.clear(orderState);
      setSelectedDay(undefined);
      setRemoveOrder(false);
      setTotalDays(0);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeOrder]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && selectedDay && selectedDay?.length >= 0) {
      setTotalDays(selectedDay?.length);
    }
    return () => {
      isMounted = false;
    };
  }, [selectedDay]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && currentDates !== undefined && currentDates !== null) {
      setSelectedDay(JSON.parse(currentDates));
    }
    return () => {
      isMounted = false;
    };
  }, [currentDates]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      refetch();
    }
    if (isMounted && localStorage.getItem(BOOKING_DAYS)) {
      const staticDatesSelected = localStorage.getItem(BOOKING_DAYS);
      let preSelectedDates: Day[] = JSON.parse(staticDatesSelected!);
      if (
        preSelectedDates &&
        preSelectedDates.filter(
          (e) => e.day < dayToday.day && dayToday.month === e.month
        )
      ) {
        preSelectedDates = preSelectedDates.filter(
          (e) => e.day >= dayToday.day && dayToday.month === e.month
        );
      }
      if (
        preSelectedDates &&
        (moment().hour() > 8 ||
          (moment().hour() === 8 && moment().minutes() > 30))
      ) {
        preSelectedDates = preSelectedDates.filter(
          (e) => e.day > dayToday.day && dayToday.month === e.month
        );
        setSelectedDay(preSelectedDates);
      } else {
        setSelectedDay(preSelectedDates);
      }
    }
    if (isMounted && localStorage.getItem(BOOKING_DAYS) !== null && state) {
      setOpenCancelModal(true);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdded = () =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={`Added ${
          selectedDay ? selectedDay.length : 'fridge for'
        } delivery ${selectedDay && selectedDay.length > 1 ? 'days' : 'day.'}`}
        title="Added"
      />,
      { position: 'top-right', autoClose: 2000 }
    );

  const {
    unavailableDates: currMonth,
    loading: fridgesLoading,
    error,
  } = useAvailableFridgeDates(
    dayToday.month,
    [fridge ? fridge.id : 0],
    me?.email!,
    dayToday.year
  );

  // if (loading || fridgesLoading) {
  //   return <LoadingPage />;
  // }
  if (error && fridge && fridge.id && me) {
    return (
      <ErrorPage
        action={
          <div
            onClick={() => navigate('/fridge-select')}
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Select a Cubbi
          </div>
        }
        error={error.graphQLErrors.map(({ message }) => `${message}`)}
      />
    );
  }
  const datesToPassToBackend = currMonth?.filter((e) => e.available === false);
  const datesFromBackend: Day[] = datesToPassToBackend?.map((e) => ({
    day: e.day,
    month: e.month,
    year: e.year,
  })) || [{ day: dayToday.day, month: dayToday.month, year: dayToday.year }];

  function handleDays() {
    track('add_delivery_click', { booking_days: totalDays });
    localStorage.setItem(BOOKING_DAYS, JSON.stringify(selectedDay));
    handleAdded();
    navigate('/kitchen');
  }

  return (
    <>
      <div className="flex h-screen flex-col">
        <header className="border-grey w-full  text-center">
          <TopNavigation title={'Date Select'} url="/upcoming" />
          {fridge && <ModifiedFridgeItem fridge={fridge} change={true} />}
        </header>
        <DateSelectFooter
          totalDays={totalDays}
          handleDays={handleDays}
          firstTimeUser={me?.firstTimeUser ? me?.firstTimeUser : false}
        />
        {me?.firstTimeUser && (
          <FridgeWalkthroughSteps step={selectedDay ? 2 : 1} />
        )}
        <div className="flex flex-col items-center justify-center pb-8">
          {loading || fridgesLoading ? (
            <DateSelectSkeleton />
          ) : (
            <Calendar
              colorPrimary="#1A56DB"
              value={selectedDay ? selectedDay : []}
              shouldHighlightWeekends={false}
              onChange={(value: Day[]) => setSelectedDay(value)}
              locale={myCustomLocale}
              selectorStartingYear={currentYear}
              selectorEndingYear={currentYear}
              disabledDays={disabledDays(
                datesFromBackend,
                fridge?.location?.city
              )}
              minimumDate={dayToday}
              maximumDate={handleMaxDate()}
            />
          )}
        </div>
      </div>
      <div className="pb-16"></div>
      <CancelOrderModal
        open={openCancelModal}
        setOpen={setOpenCancelModal}
        setClearOrder={setRemoveOrder}
      />
    </>
  );
}
