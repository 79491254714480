import {
  classNames,
  isDiscountPriceValid,
  useCloudinaryImage,
} from '../helpers';
import { Product } from '../types';
import moment from 'moment';
import { Moment } from 'moment';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';

interface Props {
  product: Product;
  handleOpenProductModal: (product: Product) => unknown;
  orderItems: OrderState[];
  currentDate: Moment;
}

export default function FeaturedProductCard({
  product,
  handleOpenProductModal,
  currentDate,
  orderItems,
}: Props) {
  const {
    name,
    image,
    price,
    quantity,
    shelfLife,
    arrivalDate,
    nextArrivalDate,
    nextQuantity,
    sameDayOrders,
    prepTime,
    discountPrice,
  } = product;
  const showDiscountPrice = isDiscountPriceValid(product, currentDate);
  let soldOut = false;
  const productImage = useCloudinaryImage(image);
  const productOrderedQuantity = orderItems.filter(
    (e) => e.product.product.id === product.id
  );

  function handleAvailable() {
    soldOut = false;
  }

  function handleUnavailable() {
    soldOut = true;
  }

  const shelfLifeNumber = shelfLife?.substring(0, 1);
  const timeNow = moment();

  const nextArrivalFirst =
    nextArrivalDate !== undefined &&
    arrivalDate !== undefined &&
    moment(nextArrivalDate).isBefore(arrivalDate);

  const nextArrival =
    nextArrivalDate !== null && currentDate.isBefore(moment(nextArrivalDate));
  const productArrival =
    arrivalDate !== null && currentDate.isBefore(moment(arrivalDate));
  const infiniteQuantity =
    arrivalDate !== null &&
    shelfLife !== 'None' &&
    currentDate.isAfter(moment(arrivalDate).add(shelfLifeNumber, 'days'));
  const nextArrivalInfinite =
    nextArrivalDate !== null &&
    shelfLife !== 'None' &&
    currentDate.isAfter(moment(nextArrivalDate).add(shelfLifeNumber, 'days'));
  const nextArrivalFinite =
    nextArrivalDate !== null &&
    nextQuantity !== null &&
    currentDate.isAfter(moment(nextArrivalDate)) &&
    currentDate.isBefore(moment(nextArrivalDate).add(shelfLifeNumber, 'days'));
  const finiteQuantity =
    arrivalDate !== null &&
    currentDate.isAfter(moment(arrivalDate)) &&
    !infiniteQuantity;
  const quantityCheck =
    quantity !== null &&
    quantity >= 0 &&
    productOrderedQuantity.length >= quantity;

  const nextArrivalQuantityCheck =
    nextQuantity !== null &&
    product.nextQuantity >= 0 &&
    productOrderedQuantity.length >= product.nextQuantity;

  const currentDateAt9 = moment(currentDate).set({
    hour: 9,
    minute: 0,
    second: 0,
  });

  const today = moment();

  const currentDateCutoff = currentDateAt9.subtract(prepTime, 'hours');
  if (
    sameDayOrders &&
    (timeNow.isAfter(currentDateCutoff) ||
      currentDate.date() === today.date()) &&
    (quantity === 0 || !quantity) &&
    (nextQuantity === 0 || !nextQuantity)
  ) {
    handleUnavailable();
  } else if (sameDayOrders && timeNow.isBefore(currentDateCutoff)) {
    handleAvailable();
  } else {
    if (!nextArrivalFirst) {
      if (productArrival) {
        handleUnavailable();
      } else if (infiniteQuantity) {
        if (
          nextArrivalDate !== null &&
          currentDate.isBefore(moment(nextArrivalDate))
        ) {
          handleUnavailable();
        } else if (nextArrivalInfinite) {
          handleAvailable();
        } else if (nextArrivalFinite) {
          if (nextArrivalQuantityCheck) {
            handleUnavailable();
          } else {
            handleAvailable();
          }
        } else {
          handleAvailable();
        }
      } else if (finiteQuantity) {
        if (quantityCheck) {
          handleUnavailable();
        } else {
          handleAvailable();
        }
      } else {
        if (quantityCheck) {
          handleUnavailable();
        } else {
          handleAvailable();
        }
      }
    } else {
      if (nextArrival) {
        handleUnavailable();
      } else if (nextArrivalInfinite) {
        if (productArrival) {
          handleUnavailable();
        } else if (infiniteQuantity) {
          handleAvailable();
        } else if (finiteQuantity) {
          if (quantityCheck) {
            handleUnavailable();
          } else {
            handleAvailable();
          }
        } else {
          handleAvailable();
        }
      } else if (nextArrivalFinite) {
        if (nextArrivalQuantityCheck) {
          handleUnavailable();
        } else {
          handleAvailable();
        }
      } else {
        if (nextArrivalQuantityCheck) {
          handleUnavailable();
        } else {
          handleAvailable();
        }
      }
    }
  }
  return (
    <div className="mb-4 w-36 flex-shrink-0 snap-center items-center rounded-xl bg-white shadow-lg">
      <div onClick={() => handleOpenProductModal(product)}>
        <img
          src={productImage}
          alt={name}
          className={classNames(
            'pointer-events-none mx-auto mb-3 mt-4 h-28 w-28 rounded-xl object-cover group-hover:opacity-75',
            soldOut ? 'opacity-50' : undefined
          )}
        />
        <div className="flex justify-between px-4 text-lg font-bold text-gray-900">
          {showDiscountPrice && (
            <>
              <div className={classNames(soldOut ? 'opacity-40' : undefined)}>
                {`$${Number(discountPrice).toFixed(2)}`}
              </div>
            </>
          )}
          {showDiscountPrice ? (
            <div
              className={classNames(
                soldOut
                  ? 'font-medium text-gray-400 line-through opacity-40'
                  : 'font-medium text-gray-400 line-through'
              )}
            >{`$${Number(price).toFixed(2)}`}</div>
          ) : (
            <div
              className={classNames(soldOut ? 'text-lg opacity-40' : 'text-lg')}
            >{`$${Number(price).toFixed(2)}`}</div>
          )}
        </div>
        <div className="px-4 pb-4 text-gray-900">{name}</div>
      </div>
    </div>
  );
}
