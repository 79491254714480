import { getNodeDataFromEdges } from '../helpers';
import { Product, QueryResult } from '../types';
import FeaturedProductCard from './featured-card';
import moment, { Moment } from 'moment';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';

interface Props {
  products: Product[];
  handleOpenProductModal: (product: Product) => unknown;
  currentDate: Moment;
  orderItems: OrderState[];
}

export default function FeaturedProducts({
  products,
  handleOpenProductModal,
  currentDate,
  orderItems,
}: Props) {
  return (
    <div className="flex snap-x snap-mandatory space-x-3 overflow-x-auto px-3">
      {products.map((product, index) => (
        <FeaturedProductCard
          key={index}
          product={product}
          handleOpenProductModal={() => handleOpenProductModal(product)}
          currentDate={currentDate}
          orderItems={orderItems}
        />
      ))}
    </div>
  );
}
