import moment from 'moment';
import {
  FridgeStatePersistentStorageItem,
  FRIDGE_STATE_KEY,
} from '../persistent-storage/fridge-state.persistent-storage';
import { usePersistentStorageItem } from '../persistent-storage/hooks';
import {
  useCoupons,
  useCredits,
  useSubsidies,
} from '../services/discounts.service';

interface Props {
  email?: string;
  fridge?: number;
}

const now = moment();
export default function DiscountUserInfo({ email, fridge }: Props) {
  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const [selectedFridge, refreshFridgeSelect] = usePersistentStorageItem(
    selectedFridgeState,
    undefined
  );
  const { discounts: coupons, loading: couponsLoading } = useCoupons(
    email,
    now,
    selectedFridge?.id
  );
  const { discounts: subisides, loading: subsidiesLoading } = useSubsidies(
    email,
    now,
    selectedFridge?.id
  );
  const { credits, loading: creditsLoading } = useCredits(email);

  return (
    <div className="mt-2 flex flex-row justify-center gap-4">
      <div className="w-1/4 rounded bg-indigo-200 py-2 text-center">
        {creditsLoading ? (
          <div className="text-xl font-semibold">...</div>
        ) : (
          <div className=" text-xl font-semibold">
            ${credits?.edges[0] ? credits?.edges[0].node?.balance : '0.00'}
          </div>
        )}{' '}
        <div className="text-md text-gray-600">Credits</div>
      </div>
      <div className="w-1/4 rounded bg-green-100 py-2 text-center">
        {couponsLoading ? (
          <div className="text-xl font-semibold">...</div>
        ) : (
          <div className="text-xl font-semibold">
            {coupons?.edges?.length ?? 0}
          </div>
        )}{' '}
        <div className="text-md text-gray-600">Coupons</div>
      </div>
      <div className="w-1/4 rounded bg-yellow-100 py-2 text-center">
        {subsidiesLoading ? (
          <div className="text-xl font-semibold">...</div>
        ) : (
          <div className="text-xl font-semibold">
            {subisides?.edges?.length ?? 0}
          </div>
        )}{' '}
        <div className="text-md text-gray-600">Subsidies</div>
      </div>
    </div>
  );
}
