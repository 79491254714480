import moment from 'moment';
import { isDiscountPriceValid, useCloudinaryImage } from '../helpers';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';

interface Props {
  state: OrderState;
  removeItem: () => void;
  addonsPrice: number[];
}

export default function ProductCard({ state, removeItem, addonsPrice }: Props) {
  const {
    product: { product },
    date,
  } = state;
  const image = useCloudinaryImage(product.image);
  const showDiscountPrice = isDiscountPriceValid(product, moment(date));
  const addonsSum =
    addonsPrice && addonsPrice.length > 0
      ? addonsPrice.reduce((a, b) => +a + +b)
      : 0;
  const discountPrice = +product.discountPrice + +addonsSum;
  const basePrice = +product.price + +addonsSum;
  return (
    <div className="mt-4 flex flex-row rounded-lg bg-white shadow-md">
      <div className="flex flex-col p-4">
        <div className="h-14 w-14">
          <img
            src={image}
            className="h-14 w-14 rounded-md object-cover"
            alt={product.name}
          />
        </div>
      </div>
      <div className="my-4 flex w-full flex-col">
        {showDiscountPrice ? (
          <div className="flex flex-row gap-2">
            <div className="text-sm font-medium text-gray-400 line-through">
              {basePrice}
            </div>
            <div className="text-sm font-semibold leading-5 text-gray-900">
              {discountPrice}
            </div>
          </div>
        ) : (
          <div className="text-sm font-semibold leading-5 text-gray-900">
            {basePrice}
          </div>
        )}
        <div className="flex w-full flex-row justify-between">
          <div className="text-sm font-medium leading-5 text-gray-900">
            {product.name}
          </div>
          <div
            className="cursor-pointer px-4 text-sm font-medium leading-5 text-red-500"
            onClick={removeItem}
          >
            Remove
          </div>
        </div>
        <div className="mt-1 text-sm font-medium leading-5">{'12 oz'}</div>
      </div>
    </div>
  );
}
