import { track } from '@amplitude/analytics-browser';

interface Props {
  totalDays: number;
  handleDays: () => void;
  firstTimeUser: boolean;
}
export default function DateSelectFooter({
  totalDays,
  handleDays,
  firstTimeUser,
}: Props) {
  return (
    <footer
      className="mb-0 flex max-h-64 w-full flex-col justify-self-stretch lg:self-center"
      aria-labelledby="footer-heading"
    >
      <div className="flex items-center justify-between py-6 px-2 sm:px-6 lg:px-8">
        <div>
          <h3 className="mr-2 px-2 text-gray-500 marker:text-sm">
            Available dates for this pod
          </h3>
        </div>
        <div className="rounded-md sm:mt-0 ">
          {totalDays ? (
            <button
              type="submit"
              className="rounded-md border border-transparent bg-indigo-500 px-3 py-2.5 text-sm text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800"
              onClick={handleDays}
            >
              {firstTimeUser ? `Browse Menu` : `Add ${totalDays} Deliveries`}
            </button>
          ) : (
            <button
              onClick={() => {
                if (!firstTimeUser) {
                  track('add_delivery_click', { booking_days: 1 });
                }
              }}
              type="submit"
              disabled={true}
              className="rounded-md border border-transparent bg-indigo-500 px-3 py-2.5 text-sm text-white opacity-40 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800"
            >
              {firstTimeUser ? `Browse Menu` : 'Add Delivery'}
            </button>
          )}
        </div>
      </div>
    </footer>
  );
}
