import { gql, useQuery } from '@apollo/client';
import { CheckDiscount, PriceTotal, ProductWithAddons } from '../types';
import { useCredits } from './discounts.service';

export const GET_PAYMENT_INTENT = gql`
  mutation getPaymentIntentBulk($input: [GetPaymentIntentInput]!) {
    getPaymentIntentBulk(input: $input) {
      ok
      clientSecret
    }
  }
`;

export const GET_DISCOUNT_VERIFY = gql`
  query checkDiscountCode(
    $products: [ProductsAddons]
    $user: String
    $fridges: [Int]
    $discountCode: String
    $dates: [DateTime]
  ) {
    checkDiscountCode(
      products: $products
      user: $user
      fridges: $fridges
      discountCode: $discountCode
      startDates: $dates
    ) {
      codeExists
      usableCode
      error
    }
  }
`;

export const GET_TOTALS = gql`
  query priceTotal(
    $products: [ProductsAddons]
    $user: String
    $fridges: [Int]
    $discountCode: String
    $startDates: [DateTime]
    $useCredits: Boolean
  ) {
    priceTotal(
      products: $products
      user: $user
      fridges: $fridges
      discountCode: $discountCode
      startDates: $startDates
      useCredits: $useCredits
    ) {
      subTotal
      taxTotal
      discountTotal
      multiDayDiscount
      incartAdditionsDiscount
      shippingTotal
      total
      error
    }
  }
`;
export const GET_TOTALS_WITH_DISCOUNT = gql`
  query priceTotal(
    $products: [ProductsAddons]
    $user: String
    $fridges: [Int]
    $discountCode: String
    $startDates: [DateTime]
    $bookings: Int
    $useCredits: Boolean
  ) {
    priceTotal(
      products: $products
      user: $user
      fridges: $fridges
      discountCode: $discountCode
      startDates: $startDates
      bookings: $bookings
      useCredits: $useCredits
    ) {
      subTotal
      taxTotal
      discountTotal
      multiDayDiscount
      shippingTotal
      incartAdditionsDiscount
      total
      error
      creditsUsed
    }
  }
`;

export function usePriceTotals(
  products: ProductWithAddons[],
  fridge?: number,
  email?: string,
  discountCode?: string,
  startDates?: string[],
  useCredits?: boolean
) {
  const { data, loading, error, refetch } = useQuery<{
    priceTotal: PriceTotal;
  }>(GET_TOTALS, {
    variables: {
      products,
      user: email,
      fridges: [fridge],
      discountCode,
      startDates,
      useCredits,
    },
    skip: fridge === undefined || !startDates,
  });
  const priceTotal: PriceTotal | undefined = data?.priceTotal;
  return { priceTotal, loading, error, refetch };
}

export function usePriceTotalsWithDiscount(
  products: ProductWithAddons[],
  fridge?: number,
  email?: string,
  discountCode?: string | null,
  useCredits?: boolean,
  startDates?: string[] | undefined,
  booking?: number
) {
  const { data, loading, error, refetch } = useQuery<{
    priceTotal: PriceTotal;
  }>(GET_TOTALS_WITH_DISCOUNT, {
    variables: {
      products,
      user: email,
      fridges: [fridge],
      discountCode,
      startDates,
      bookings: booking ?? null,
      useCredits,
    },
    skip:
      fridge === undefined || email === undefined || startDates === undefined,
  });
  const priceTotal: PriceTotal | undefined = data?.priceTotal;
  return { priceTotal, loading, error, refetch };
}

export function useCheckDiscount(
  products: ProductWithAddons[],
  fridge?: number,
  email?: string,
  discountCode?: string,
  startDates?: string[]
) {
  const { data, error, refetch } = useQuery<{
    checkDiscountCode: CheckDiscount;
  }>(GET_DISCOUNT_VERIFY, {
    variables: {
      products,
      user: email,
      fridges: [fridge],
      discountCode,
      dates: startDates,
    },
    skip: fridge === undefined || discountCode === '',
    fetchPolicy: 'network-only',
  });
  const discountData: CheckDiscount | undefined = data?.checkDiscountCode;
  return { discountData, error, refetch };
}

export const DISCOUNT_ORDER_VERIFY = gql`
  mutation discountOrderVerify($input: FridgeInput!) {
    discountOrderVerify(input: $input) {
      success
    }
  }
`;
