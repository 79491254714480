import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SelectManyAddon from '../add-ons/select-many-addon';
import SingleAddon from '../add-ons/single-addon';
import TwoThreeAddon from '../add-ons/two-three-addon';
import { SelectionType } from '../constants';
import { classNames, getSelectionType } from '../helpers';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';
import NutritionInfo from '../product-info-modal/nutrition-info';
import ProductInfoHeader from '../product-info-modal/product-info-header';
import SoldOutInfo from '../product-info-modal/sold-out-info';
import { AddonGroup, Category, Product, ProductObj } from '../types';
import Alert, { AlertType } from './alert';
import { reset, track } from '@amplitude/analytics-browser';
import { isDiscountPriceValid } from '../helpers';

interface Props {
  product: Product;
  close: () => void;
  currentDate: Moment;
  addItemToOrder: (product: ProductObj) => unknown;
  orderItems: OrderState[];
  category: Category;
}

type objectType = {
  [key: string]: Array<number>;
};

export default function ProductInfo({
  product,
  currentDate,
  close,
  addItemToOrder,
  orderItems,
  category,
}: Props) {
  const {
    arrivalDate,
    shelfLife,
    quantity,
    availableDates,
    nextArrivalDate,
    nextQuantity,
    sameDayOrders,
    prepTime,
  } = product;
  const cutOffDay = product.cutoffTime.split(',')[0];
  const almostSoldOut =
    product.quantity !== null && product.quantity <= 3 && product.quantity > 0;
  const discountPrice = isDiscountPriceValid(product, moment(currentDate));

  const navigate = useNavigate();
  const [errors, setErrors] = useState(new Set());
  const [lengthError, setLengthError] = useState(false);
  const [addonsPrices, setAddonsPrices] = useState(
    product.addons && product.addons.length > 0
      ? product.addons.map((e) => {
          return {
            id: e.id,
            price: [0],
            addonId: [0],
            selectionType: e.selection,
          };
        })
      : []
  );

  const addonTotal =
    product.addons &&
    product.addons.length > 0 &&
    addonsPrices &&
    addonsPrices.length > 0
      ? addonsPrices
          .map((e) => e.price)
          .flatMap((e) => e)
          .reduce((a, b) => +a + +b)
      : 0;
  const price = discountPrice
    ? +product.discountPrice + +addonTotal
    : +product.price + +addonTotal;
  const addError = (type: SelectionType) => {
    setErrors((prevState) => new Set([...prevState, type]));
  };

  const timeNow = moment();
  const today = moment();
  const nextDay = moment().add(1, 'day');
  const currentDayFormatted = currentDate.format('dddd');
  const isAvailableDay = availableDates.includes(currentDayFormatted);

  let soldOut = false;
  let greyOut = false;

  const productOrderedQuantity = orderItems.filter(
    (e) => e.product.product.id === product.id
  );

  function handleAvailable() {
    soldOut = false;
    greyOut = false;
  }

  function handleUnavailable() {
    soldOut = true;
    greyOut = true;
  }
  const shelfLifeNumber = shelfLife?.substring(0, 1);

  const nextArrivalFirst =
    nextArrivalDate !== undefined &&
    arrivalDate !== undefined &&
    moment(nextArrivalDate).isBefore(arrivalDate);

  const nextArrival =
    nextArrivalDate !== null && currentDate.isBefore(moment(nextArrivalDate));
  const productArrival =
    arrivalDate !== null && currentDate.isBefore(moment(arrivalDate));
  const infiniteQuantity =
    arrivalDate !== null &&
    shelfLife !== 'None' &&
    currentDate.isAfter(moment(arrivalDate).add(shelfLifeNumber, 'days'));
  const nextArrivalInfinite =
    nextArrivalDate !== null &&
    shelfLife !== 'None' &&
    currentDate.isAfter(moment(nextArrivalDate).add(shelfLifeNumber, 'days'));
  const nextArrivalFinite =
    nextArrivalDate !== null &&
    nextQuantity !== null &&
    currentDate.isAfter(moment(nextArrivalDate)) &&
    currentDate.isBefore(moment(nextArrivalDate).add(shelfLifeNumber, 'days'));
  const finiteQuantity =
    arrivalDate !== null &&
    currentDate.isAfter(moment(arrivalDate)) &&
    !infiniteQuantity;
  const quantityCheck =
    quantity !== null &&
    quantity >= 0 &&
    productOrderedQuantity.length >= quantity;

  const nextArrivalQuantityCheck =
    nextQuantity !== null &&
    product.nextQuantity >= 0 &&
    productOrderedQuantity.length >= product.nextQuantity;

  const currentDateAt9 = moment(currentDate).set({
    hour: 9,
    minute: 0,
    second: 0,
  });

  const currentDateCutoff = currentDateAt9.subtract(prepTime, 'hours');
  if (
    sameDayOrders &&
    (timeNow.isAfter(currentDateCutoff) ||
      currentDate.date() === today.date()) &&
    (quantity === 0 || !quantity) &&
    (nextQuantity === 0 || !nextQuantity)
  ) {
    handleUnavailable();
  } else if (sameDayOrders && timeNow.isBefore(currentDateCutoff)) {
    handleAvailable();
  } else {
    if (!nextArrivalFirst) {
      if (productArrival) {
        handleUnavailable();
      } else if (infiniteQuantity) {
        if (
          nextArrivalDate !== null &&
          currentDate.isBefore(moment(nextArrivalDate))
        ) {
          handleUnavailable();
        } else if (nextArrivalInfinite) {
          handleAvailable();
        } else if (nextArrivalFinite) {
          if (nextArrivalQuantityCheck) {
            handleUnavailable();
          } else {
            handleAvailable();
          }
        } else {
          handleAvailable();
        }
      } else if (finiteQuantity) {
        if (quantityCheck) {
          handleUnavailable();
        } else {
          handleAvailable();
        }
      } else {
        if (quantityCheck) {
          handleUnavailable();
        } else {
          handleAvailable();
        }
      }
    } else {
      if (nextArrival) {
        handleUnavailable();
      } else if (nextArrivalInfinite) {
        if (productArrival) {
          handleUnavailable();
        } else if (infiniteQuantity) {
          handleAvailable();
        } else if (finiteQuantity) {
          if (quantityCheck) {
            handleUnavailable();
          } else {
            handleAvailable();
          }
        } else {
          handleAvailable();
        }
      } else if (nextArrivalFinite) {
        if (nextArrivalQuantityCheck) {
          handleUnavailable();
        } else {
          handleAvailable();
        }
      } else {
        if (nextArrivalQuantityCheck) {
          handleUnavailable();
        } else {
          handleAvailable();
        }
      }
    }
  }

  const resetErrors = () => {
    setErrors(new Set());
  };

  function getSelectionComponent(type: SelectionType, addon: AddonGroup) {
    switch (type) {
      case SelectionType.A_1:
        return (
          <SingleAddon
            addon={addon}
            addonPrices={addonsPrices}
            setAddonPrices={setAddonsPrices}
          />
        );
      case SelectionType.A_2:
        return (
          <TwoThreeAddon
            addon={addon}
            addonPrices={addonsPrices}
            setAddonPrices={setAddonsPrices}
          />
        );
      case SelectionType.A_3:
        return (
          <SelectManyAddon
            addon={addon}
            addonPrices={addonsPrices}
            setAddonPrices={setAddonsPrices}
          />
        );
      default:
        return null;
    }
  }

  async function handelSubmitWithoutIK() {
    resetErrors();
    setLengthError(false);
    const getRequiredLength = product.addons.filter(
      (e) => e.selection === ('A_1' || 'A_2')
    );

    const addonsIncludesOptional = product.addons.filter(
      (e) => e.selection === 'A_3'
    );

    const selectedAddonsRequired = addonsPrices.filter(
      (e) =>
        e.addonId &&
        e.addonId.length > 0 &&
        e.addonId.reduce((a, b) => +a + +b) !== 0 &&
        e.selectionType === (SelectionType.A_1 || SelectionType.A_2)
    );

    if (
      addonsPrices &&
      selectedAddonsRequired &&
      getRequiredLength &&
      selectedAddonsRequired.length < getRequiredLength.length
    ) {
      setLengthError(true);
      return;
    }
    if (
      product &&
      product.addons &&
      selectedAddonsRequired &&
      product.addons.length -
        selectedAddonsRequired.length -
        addonsIncludesOptional.length ===
        0 &&
      product.addons.length > 0
    ) {
      const addonIds = addonsPrices
        .filter((e) => e.addonId.map((e) => e !== 0))
        .map((e) => e.addonId)
        .flatMap((e) => e);
      const addons: AddonGroup[] = product.addons
        .flatMap((addonGroup) => {
          const selectedAddons = addonGroup.addons.filter((addon) =>
            addonIds.includes(addon.id)
          );
          return selectedAddons.length
            ? [{ ...addonGroup, addons: selectedAddons }]
            : [];
        })
        .flat();
      addItemToOrder({ product, addons });
    } else if (product.addons.length === 0) {
      addItemToOrder({ product, addons: [] });
      return;
    }
    close();
  }

  const weekArray = moment
    .weekdays()
    .filter((day) => day !== 'Sunday' && day !== 'Saturday');
  return (
    <div className="flex min-h-screen flex-col">
      <Formik initialValues={{}} validate={() => {}} onSubmit={() => {}}>
        <Form className="bg-white">
          <ProductInfoHeader
            image={product.image}
            name={product.name}
            close={close}
          />
          {soldOut && (
            <div
              className="flex items-center border-l-4 border-red-500 bg-red-100 p-4 "
              role="alert"
            >
              <div>
                <div className="mb-1 flex items-center">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mr-4 text-red-400"
                  />
                  <div className="flex">
                    <p className="mr-1 text-sm font-medium text-red-800">
                      Sold Out
                    </p>
                    <p className="text-sm font-medium  text-red-800"></p>
                  </div>
                </div>
                <div
                  onClick={() => navigate('/date-select')}
                  className="ml-9  text-sm font-medium text-red-700"
                >
                  Edit Delivery Dates →
                </div>
              </div>
            </div>
          )}
          {!isAvailableDay && (
            <div
              className="flex items-center border-l-4 border-red-500 bg-red-100 p-4 "
              role="alert"
            >
              <div>
                <div className="mb-1 flex items-center">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mr-4 text-red-400"
                  />
                  <p className="text-sm font-medium  text-red-800">
                    Invalid Delivery Day, this product is only available on:
                  </p>
                </div>
                <div className="ml-9 mb-2 flex">
                  {weekArray.map((date: string) => {
                    const includes = availableDates.includes(date);
                    return (
                      <div
                        className={classNames(
                          'mr-3 text-sm',
                          includes ? 'font-bold text-red-800' : 'text-red-300'
                        )}
                      >
                        {date.slice(0, 1)}
                      </div>
                    );
                  })}
                </div>
                <div
                  onClick={() => navigate('/date-select')}
                  className="ml-9  text-sm font-medium text-red-700"
                >
                  Edit Delivery Dates →
                </div>
              </div>
            </div>
          )}
          {/* {isAvailableDay && (
            <div
              className="flex items-center border-l-4 border-red-500 bg-red-100 p-4 "
              role="alert"
            >
              <div>
                <div className="mb-1 flex items-center">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mr-4 text-red-400"
                  />
                  <p className="text-sm font-medium  text-red-800">
                    Invalid Delivery Day, this product is not available for same
                    day delivery:
                  </p>
                </div>
                <div className="ml-9 mb-2 flex">
                  {weekArray.map((date: string) => {
                    const includes = availableDates.includes(date);
                    return (
                      <div
                        className={classNames(
                          'mr-3 text-sm',
                          includes ? 'font-bold text-red-800' : 'text-red-300'
                        )}
                      >
                        {date.slice(0, 3)}
                      </div>
                    );
                  })}
                </div>
                <div
                  onClick={() => navigate('/date-select')}
                  className="ml-9  text-sm font-medium text-red-700"
                >
                  Edit Delivery Dates →
                </div>
              </div>
            </div>
          )} */}
          <NutritionInfo
            name={product.name}
            description={product.description}
            allergens={product.allergens}
            nutritionFacts={product.nutritionfacts}
            almostSoldOut={almostSoldOut}
            totalSize={product.totalSize}
            quantity={product.quantity}
          />

          <div className="flex flex-col">
            {lengthError && (
              <Alert
                type={AlertType.Error}
                title="Required Selection Error"
                messages={[
                  'Missing required addon selection. Please select all required addons.',
                ]}
              />
            )}
            <div className="mx-5">
              {product.addons.map((addon, index) => {
                const error = errors.has(addon.selection);
                return (
                  <div key={index}>
                    <div className="text-base font-medium leading-6 text-gray-700">
                      {addon.description}
                    </div>
                    <div className="flex flex-row justify-between">
                      <div className="text-sm font-normal leading-5 text-gray-500">
                        {getSelectionType(addon.selection)}
                      </div>
                      <div className="text-sm font-normal leading-5 text-gray-500">
                        {addon.selection === SelectionType.A_3
                          ? undefined
                          : 'Required'}
                      </div>
                    </div>
                    {error && (
                      <div className="mt-2">
                        <Alert
                          type={AlertType.Error}
                          title="Required Selection Error"
                          messages={
                            addon.selection === SelectionType.A_1
                              ? [
                                  `Please select at least one option for ${addon.name}`,
                                ]
                              : [
                                  `Please select at least two options for ${addon.name}`,
                                ]
                          }
                        />
                      </div>
                    )}
                    <div className="my-6">
                      {getSelectionComponent(addon.selection, addon)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {sameDayOrders &&
            currentDate.date() === nextDay.date() &&
            ((!nextArrivalFirst && quantityCheck && !infiniteQuantity) ||
              (nextArrivalFirst &&
                nextArrivalQuantityCheck &&
                !nextArrivalInfinite)) && (
              <SoldOutInfo
                cutOffDay={cutOffDay}
                productCutOff={product.cutoffTimeString}
              />
            )}
          {sameDayOrders &&
            ((!nextArrivalFirst && quantityCheck && !infiniteQuantity) ||
              (nextArrivalFirst &&
                nextArrivalQuantityCheck &&
                !nextArrivalInfinite)) &&
            currentDate.date() === today.date() && (
              <SoldOutInfo
                cutOffDay={cutOffDay}
                productCutOff={product.cutoffTimeString}
              />
            )}
          {!greyOut && (
            <footer className="fixed inset-x-0 bottom-0 z-50">
              <div className="inline-flex w-full content-center justify-between border border-transparent bg-indigo-700 px-6 py-6  ">
                <div>
                  <div className="font-medium text-white">
                    ${Number(price).toFixed(2)}
                  </div>
                  <div className="mt-1.5 text-sm text-gray-300">
                    {moment(currentDate).format('ddd MMM Do')}
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => {
                      track('product_added_details_page', {
                        product_name: product.name,
                        product_kitchen: product.vendor,
                        product_price: product.price,
                        product_category: category.name,
                      });
                      handelSubmitWithoutIK();
                    }}
                    type="submit"
                    disabled={soldOut}
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white px-5 py-3 text-sm font-medium text-indigo-500  shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Add 1 Item
                  </button>
                </div>
              </div>
            </footer>
          )}
          <div className="mb-96" />
        </Form>
      </Formik>
    </div>
  );
}
