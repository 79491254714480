import { useMutation } from '@apollo/client';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProductImageCard from '../checkout/product-image-card';
import BottomSheetModal from '../components/bottom-sheet-modal';
import Notification from '../components/notification';
import { NotificationType } from '../constants';
import { classNames, getNodeDataFromEdges } from '../helpers';
import { useModalToggle } from '../hooks';
import TopNavigation from '../navigation/top-navigation';
import ErrorPage from '../screens/error';
import LoadingPage from '../screens/loading';
import { useCategoriesWithProducts } from '../services/products.service';
import {
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  useSubByEmail,
} from '../services/subscriptions.service';
import { useMe } from '../services/user.service';
import {
  AddonGroup,
  Product,
  SubscriptionReturnType,
  ProductObj,
} from '../types';
import GridProductCardUnconditional from './grid-product-card-unconditional';
import ProductInfoUnconditional from './product-info-unconditional';
import { FridgeStatePersistentStorageItem } from '../persistent-storage/fridge-state.persistent-storage';
import { FRIDGE_STATE_KEY } from '../persistent-storage/fridge-state.persistent-storage';
import PersistentStorage from '../persistent-storage/persistent-storage';

export interface MealSubscriptionProduct {
  product: Product;
  addons: AddonGroup[];
}

//ALTERNATE KITCHEN
//Used for meal subscriptions
export default function SubscriptionKitchen() {
  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const fridge = PersistentStorage.get(selectedFridgeState);
  const { categories, loading, error } = useCategoriesWithProducts(
    'Meals',
    fridge?.location?.city
  );
  const productModal = useModalToggle();
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const { data: me } = useMe();
  const {
    data: subscriptions,
    loading: subLoading,
    error: subError,
  } = useSubByEmail(me?.email);
  const [createSubscription] =
    useMutation<{ createSubscription: SubscriptionReturnType }>(
      CREATE_SUBSCRIPTION
    );
  const [updateSubscription] =
    useMutation<{ updateSubscription: SubscriptionReturnType }>(
      UPDATE_SUBSCRIPTION
    );
  const [selectedProducts, setSelectedProducts] = useState<
    MealSubscriptionProduct[]
  >([]);

  const visible = productModal.props.visible;

  const handleAdded = (name: string) =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={`${name} was added to your meals`}
        title="Meal Added Successfully"
      />,
      { position: 'top-right' }
    );

  const handleRemoved = (name: string) =>
    toast(
      <Notification
        type={NotificationType.Success}
        message={`${name} was removed from your meals`}
        title="Meal removed Successfully"
      />,
      { position: 'top-right' }
    );

  useEffect(() => {
    if (
      subscriptions &&
      subscriptions[0] &&
      subscriptions[0].products &&
      subscriptions.length > 0
    ) {
      const products = subscriptions[0].products;
      setSelectedProducts(
        products.map((e) => ({
          product: e,
          addons: e.addons,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptions && subscriptions[0] && subscriptions[0].products]);

  function handleOpenProductModal(product: Product) {
    setSelectedProduct(product);
    productModal.open();
  }

  function addItemToOrder({ product, addons }: MealSubscriptionProduct) {
    setSelectedProducts([...selectedProducts, { product, addons }]);
    productModal.close();
    handleAdded(product.name);
  }

  function removeItemFromOrder(product: MealSubscriptionProduct) {
    let ids = selectedProducts.map((e) => e.product.id);
    let productId = product.product.id;
    let result = ids.map((e) => e == productId);
    let currentProducts = selectedProducts;
    let item: MealSubscriptionProduct | undefined;
    if (result.includes(true)) {
      for (let i = 0; i < result.length; i++) {
        if (result[i] === true) {
          item = currentProducts[i];
          currentProducts.splice(i, 1);
        }
      }
      setSelectedProducts([...currentProducts]);
      if (item) {
        handleRemoved(item.product.name);
      }
    }
  }

  // function removeItemFromOrder(product: MealSubscriptionProduct) {
  //   const newProducts = selectedProducts.filter(
  //     (e) => e.product.id !== product.product.id
  //   );
  //   setSelectedProducts(newProducts);
  // }

  if (loading || subLoading) {
    return <LoadingPage />;
  }
  if (error || !categories || subError) {
    return (
      <ErrorPage
        error={error?.graphQLErrors.map(({ message }) => `${message}`)}
      />
    );
  }

  async function handleRedirect() {
    const products = selectedProducts.map(({ product, addons }) => ({
      product: product.id,
      addons: addons.map((e) => e.id),
    }));
    if (subscriptions && subscriptions[0]) {
      try {
        const { data: updateSub, errors: updateSubErrors } =
          await updateSubscription({
            variables: {
              id: subscriptions[0].id,
              input: { products },
            },
          });
        if (updateSub && !updateSubErrors) {
          navigate('/meal-plan');
        }
      } catch (e) {}
    } else {
      //Backup plan to create sub incase it doesn't work on Add More
      try {
        const { data: createSub, errors: createSubErrors } =
          await createSubscription({
            variables: {
              input: {
                user: me?.email,
                activate: false,
                input: { products },
              },
            },
          });
        if (createSub && !createSubErrors) {
          navigate('/meal-plan');
        }
      } catch (e) {}
    }
  }

  const products = categories[0].filteredProducts;
  const productIds = new Set(
    selectedProducts.map(({ product: { id } }) => +id)
  );
  return (
    <div className="[data-body-scroll-lock-ignore]">
      <TopNavigation title="Add to meal schedule" url="/meal-plan" />
      <div className="mt-4 px-4">
        <FontAwesomeIcon icon={faHeart} className="text-pink-400" />
        <span className=" pl-2 text-lg font-semibold">My meals</span>
      </div>
      <div className="flex space-x-3 p-4">
        {selectedProducts.slice(0, 4).map(({ product }, index) => (
          <ProductImageCard
            key={index}
            index={index}
            product={product}
            length={selectedProducts.length}
          />
        ))}
      </div>
      <div className="m-4 border border-gray-300" />
      <div className="p-4">
        <div className="relative my-4 hidden items-center" />
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8">
          {products
            .filter((item) => item.isAvailable)
            .sort(({ position: a }, { position: b }) => {
              // @ts-ignore
              return (a === null) - (b === null) || +(a > b) || -(a < b);
            })
            .map((product) => (
              <GridProductCardUnconditional
                key={product.id}
                product={product}
                selectedProductIds={productIds}
                handleOpenProductModal={handleOpenProductModal}
                addItemToOrder={addItemToOrder}
                removeItemFromOrder={removeItemFromOrder}
              />
            ))}
        </div>
      </div>
      <div className="pb-32" />
      <footer
        className={classNames(
          visible ? 'block' : 'fixed inset-x-0 bottom-0 z-10 bg-white'
        )}
      >
        <div className="inline-flex w-full content-center justify-center border border-transparent px-4 pt-4 pb-4">
          <div className="w-full pr-4">
            <button
              type="submit"
              className="inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-5 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={handleRedirect}
            >
              Save changes
            </button>
          </div>
        </div>
      </footer>
      <div className="oveflow-scroll-y">
        <BottomSheetModal
          visible={visible}
          close={productModal.close}
          height={0.935}
          blocked={true}
        >
          <></>
          {/* {selectedProduct && (
            <ProductInfoUnconditional
              addItemToOrder={addItemToOrder}
              close={productModal.close}
              product={selectedProduct}
              isSelected={productIds.has(+selectedProduct.id)}
              removeItemFromOrder={removeItemFromOrder}
            />
          )} */}
        </BottomSheetModal>
      </div>
    </div>
  );
}
