import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { classNames } from '../helpers';
import { Addon, AddonGroup } from '../types';
import { Field } from 'formik';
import { SelectionType } from '../constants';
import { Dispatch } from 'react';

interface Props {
  addon: AddonGroup;
  addonPrices: {
    id: number;
    price: number[];
    addonId: number[];
    selectionType: SelectionType;
  }[];
  setAddonPrices: Dispatch<
    SetStateAction<
      {
        id: number;
        price: number[];
        addonId: number[];
        selectionType: SelectionType;
      }[]
    >
  >;
}

export default function SingleAddon({
  addon: group,
  addonPrices,
  setAddonPrices,
}: Props) {
  const { addons } = group;
  const [selected, setSelected] = useState<undefined | Addon>();

  function addAddon(value: any) {
    let currAddonPrices = addonPrices;
    const currIndex = currAddonPrices.findIndex((e) => e.id === group.id);

    if (selected && selected.id === value.id) {
      currAddonPrices[currIndex] = {
        id: group.id,
        price: [0],
        addonId: [0],
        selectionType: group.selection,
      };
      setSelected(undefined);
    } else if (selected && selected.id !== value.id) {
      currAddonPrices[currIndex] = {
        id: group.id,
        price: [value.price],
        addonId: [selected.id],
        selectionType: group.selection,
      };
      setSelected(() => value);
    } else if (!selected) {
      currAddonPrices[currIndex] = {
        id: group.id,
        price: [value.price],
        addonId: [value.id],
        selectionType: group.selection,
      };
      setSelected(() => value);
    } else {
      setSelected(undefined);
    }
    setAddonPrices([...currAddonPrices]);
  }

  return (
    <div className="-space-y-px rounded-md bg-white">
      {group.addons.map((addon, addonIdx) => (
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Option
            key={addon.name}
            value={addon}
            className={({ checked }) =>
              classNames(
                addonIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                addonIdx === addons.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked ? 'border-indigo-200 bg-indigo-50' : 'border-gray-200',
                'relative cursor-pointer items-center border p-4 focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <div className="flex flex-row items-center justify-between">
                <div>
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? 'text-indigo-900' : 'text-gray-900',
                      'block text-sm font-medium'
                    )}
                  >
                    {addon.name}
                  </RadioGroup.Label>

                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? 'text-indigo-700' : 'text-gray-500',
                      'block text-sm'
                    )}
                  >
                    {addon.price > 0 ? `Add $${addon.price}` : 'Free'}
                  </RadioGroup.Description>
                </div>

                <Field
                  type="checkbox"
                  name={SelectionType.A_1}
                  value={addon.id}
                  checked={selected && selected.id === addon.id}
                  onClick={() => addAddon(addon)}
                  className={classNames(
                    checked
                      ? 'border-transparent bg-indigo-600'
                      : 'border-gray-300 bg-white',
                    active ? 'ring-2 ring-indigo-500 ring-offset-2' : '',
                    'mt-0.5 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border'
                  )}
                />
              </div>
            )}
          </RadioGroup.Option>
        </RadioGroup>
      ))}
    </div>
  );
}
