import { ReactNode, createContext, useEffect, useState } from 'react';
import { Discount } from '../types';

interface Props {
  children: ReactNode;
}

export const DiscountContext = createContext<[Discount | null, any]>([
  null,
  () => {},
]);

const DiscountProvider = ({ children }: Props) => {
  const [discount, setDiscount] = useState(null);

  useEffect(() => {
    if (discount === undefined) return;
    localStorage.setItem('discount', JSON.stringify(discount));
  }, [discount]);

  useEffect(() => {
    const discountCode = localStorage.getItem('discount');
    const parsedCode = discountCode ? JSON.parse(discountCode) : undefined;
    if (parsedCode) {
      setDiscount(parsedCode);
    }
  }, []);

  return (
    <DiscountContext.Provider value={[discount, setDiscount]}>
      {children}
    </DiscountContext.Provider>
  );
};

export default DiscountProvider;
