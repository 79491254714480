import { useNavigate } from 'react-router-dom';
import ProgressIndicator from '../../onboarding/progress-indicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { PinInput } from 'react-input-pin-code';
import { useState } from 'react';
import { classNames, useWindowDimensions } from '../../helpers';
import { useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';
import signupPhone from '../../img/lotties/signup-phone.json';
import { VERIFY_SMS, SEND_VERIFY_SMS } from '../../services/user.service';
import { GenericResponse, AuthResponse } from '../../types';
import { useMutation } from '@apollo/client';
import { LOGIN_PHONE } from '../../services/auth.service';
import Notification from '../../components/notification';
import { toast } from 'react-toastify';
import { NotificationType } from '../../constants';
import { TOKEN, REFRESH_TOKEN, FRIDGE } from '../../constants';
import { User } from '../../types';
import cubbiPng from '../../img/logo.png';
import {
  SEND_SMS_LOGIN_TOKEN,
  SEND_EMAIL_LOGIN_TOKEN,
} from '../../services/auth.service';
import { LOGIN_EMAIl } from '../../services/auth.service';
import { TokenAuthError } from '../../constants';
import { AuthContext } from '../../providers/auth-provider';
import { useContext } from 'react';

interface loginState {
  isEmail: boolean;
  email?: string;
  phone?: string;
}

export default function LoginCode() {
  const { setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as loginState;
  const { phone, email, isEmail } = state;
  const [values, setValues] = useState<string[]>(['', '', '', '', '']);
  const [pin, setPin] = useState<string>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [sendSmsLoginToken] =
    useMutation<{ sendSmsLoginToken: GenericResponse }>(SEND_SMS_LOGIN_TOKEN);
  const [sendEmailLoginToken] = useMutation<{
    sendEmailLoginToken: GenericResponse;
  }>(SEND_EMAIL_LOGIN_TOKEN);
  const [loginPhone] = useMutation<{
    tokenAuth: AuthResponse;
  }>(LOGIN_PHONE);
  const [loginEmail] = useMutation<{
    tokenAuth: AuthResponse;
  }>(LOGIN_EMAIl);

  const handleError = (message: string) =>
    toast(
      <Notification
        type={NotificationType.Error}
        message={`${message}`}
        title="Error"
      />,
      { position: 'top-center', autoClose: 5000 }
    );

  async function loginPhoneCode() {
    setLoading(true);
    const { data } = await loginPhone({
      variables: {
        phone,
        token: pin,
      },
      onError: (e) => {
        handleError(e.message);
      },
    });
    setLoading(false);
    return data;
  }

  async function loginEmailCode() {
    setLoading(true);
    const { data } = await loginEmail({
      variables: {
        email,
        token: pin,
      },
      onError: (e) => {
        handleError(e.message);
      },
    });
    setLoading(false);
    return data;
  }

  async function handleResendText() {
    await sendSmsLoginToken({
      variables: {
        input: {
          phone,
        },
      },
      onError: (e) => {
        handleError(e.message);
      },
    });
  }

  async function handleResendEmail() {
    await sendEmailLoginToken({
      variables: {
        input: {
          email,
        },
      },
      onError: (e) => {
        handleError(e.message);
      },
    });
  }

  async function setupUser(authResponse: AuthResponse) {
    localStorage.setItem(TOKEN, authResponse.token);
    localStorage.setItem(REFRESH_TOKEN, authResponse.refreshToken);
    setAuthState({ token: authResponse.token, setAuthState });
    if (
      authResponse &&
      authResponse.user &&
      authResponse.user.defaultPod &&
      authResponse.user.defaultPod.id
    ) {
      localStorage.setItem(
        FRIDGE,
        JSON.stringify(authResponse.user.defaultPod.id)
      );
    }
  }

  async function handleNext() {
    const data = await (isEmail ? loginEmailCode() : loginPhoneCode());
    try {
      if (data?.tokenAuth) {
        if (data?.tokenAuth.errors) {
          throw new Error(
            data?.tokenAuth.errors.nonFieldErrors[0].code ===
            TokenAuthError.InvalidCredentials
              ? 'The code you entered is incorrect.'
              : 'Something went wrong'
          );
        }
        await setupUser(data.tokenAuth);
        if (!data.tokenAuth.user.firstName && !data.tokenAuth.user.lastName) {
          navigate('/signup-name', { state: { user: data.tokenAuth.user } });
        } else if (data.tokenAuth.user.email.includes('cubbi_temp_')) {
          navigate('/signup-email', { state: { user: data.tokenAuth.user } });
        } else {
          navigate('/upcoming');
        }
      }
    } catch (e: any) {
      setError(e.message);
    }
  }

  return (
    <div className={`flex h-screen w-screen justify-center bg-background p-4`}>
      <div className="lg:w-3/8">
        <div className="py-4 pb-8">
          <img className="px-6, h-6" src={cubbiPng} alt="cubbi" />
        </div>
        <div className="items-center">
          <div
            className="flex flex-row items-center"
            onClick={() => navigate('/login')}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="text-xl text-gray-500"
            />
            <div className={`pl-4 text-2xl font-bold text-graphite-900`}>
              Log in easily without a password
            </div>
          </div>
          <div className="py-4 text-graphite-900">
            {!isEmail && (
              <div className="flex items-start justify-start pb-4">
                <Lottie
                  style={{ height: 80 }}
                  loop
                  animationData={signupPhone}
                />
              </div>
            )}
            <span>Enter the code sent to</span>
            {isEmail ? (
              <span className="font-bold"> {email}.</span>
            ) : (
              <span className="font-bold"> {phone ?? null}.</span>
            )}
          </div>
          <div className="flex flex-col rounded-lg bg-white py-4 px-6 pt-4 shadow-lg sm:w-96">
            <div className="py-2">
              <PinInput
                inputClassName="px-4"
                containerClassName="w-full justify-between"
                inputStyle={{ width: 48 }}
                type="text"
                values={values}
                onChange={(value, index, values) => {
                  setValues(values);
                  setPin(values.join(''));
                }}
                focusBorderColor={'rgb(99,102,241)'}
                validBorderColor={'rgb(99,102,241)'}
                placeholder=""
                inputMode={'numeric'}
                autoComplete="one-time-code"
              />
              <p
                className={'pt-4 text-sm text-primary-700'}
                onClick={() =>
                  isEmail ? handleResendEmail() : handleResendText()
                }
              >
                Didn't get it? Resend code
              </p>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={pin && pin.length < 5 ? true : false}
                  className={classNames(
                    pin && pin.length < 5
                      ? 'bg-indigo-600/20'
                      : pin
                      ? 'bg-indigo-600'
                      : 'bg-indigo-600/20',
                    'group relative flex w-full justify-center rounded-md border border-transparent p-3 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  )}
                  onClick={() => handleNext()}
                >
                  <span className="flex items-center text-base"></span>
                  {loading ? 'Loading...' : 'Next'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
