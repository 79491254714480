import { Field } from 'formik';
import { useEffect, useState } from 'react';
import { SelectionType } from '../constants';
import { classNames } from '../helpers';
import { Addon, AddonGroup } from '../types';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  addon: AddonGroup;
  addonPrices: {
    id: number;
    price: number[];
    addonId: number[];
    selectionType: SelectionType;
  }[];
  setAddonPrices: Dispatch<
    SetStateAction<
      {
        id: number;
        price: number[];
        addonId: number[];
        selectionType: SelectionType;
      }[]
    >
  >;
}

export default function SelectManyAddon({
  addon: group,
  addonPrices,
  setAddonPrices,
}: Props) {
  const { addons } = group;
  const [state, setState] = useState(new Set<Addon>());

  const addAddon = (addon: Addon) => {
    setState((prevState) => new Set([...prevState, addon]));
  };

  const removeAddon = (addon: Addon) => {
    setState((prev) => new Set([...prev].filter((x) => x !== addon)));
  };

  function handleChange(addon: Addon) {
    if (state.has(addon)) {
      removeAddon(addon);
    } else {
      addAddon(addon);
    }
  }

  useEffect(() => {
    if (state) {
      const addonsToPrices: Addon[] = Array.from(state);
      //don't overwrite when no addons
      if (addonsToPrices && addonsToPrices.length === 0) {
        let currAddonPrices = addonPrices;
        const index = currAddonPrices.findIndex((e) => e.id === group.id);
        if (currAddonPrices && currAddonPrices[index]) {
          currAddonPrices[index] = {
            id: group.id,
            price: [0],
            addonId: [0],
            selectionType: group.selection,
          };
          setAddonPrices([...currAddonPrices]);
        }
        return;
      }
      let currAddonPrices = addonPrices;
      const index = currAddonPrices.findIndex((e) => e.id === group.id);
      const addonReturnPrices = addonsToPrices.map((e) => e.price);
      const addonReturnIds = addonsToPrices.map((e) => e.id);
      if (currAddonPrices && currAddonPrices[index]) {
        currAddonPrices[index] = {
          id: group.id,
          price: addonReturnPrices,
          addonId: addonReturnIds,
          selectionType: group.selection,
        };
        setAddonPrices([...currAddonPrices]);
      }
    }
  }, [state]);

  return (
    <div className="-space-y-px rounded-md bg-white">
      {addons.map((addon, addonIdx) => {
        const checked = state.has(addon);
        return (
          <div
            className={classNames(
              addonIdx === 0 ? 'rounded-tl-md rounded-tr-md' : undefined,
              addonIdx === addons.length - 1
                ? 'rounded-bl-md rounded-br-md'
                : undefined,
              checked ? 'border-indigo-200 bg-indigo-50' : 'border-gray-200',
              'relative cursor-pointer items-center border p-4 focus:outline-none'
            )}
          >
            <div className="flex flex-row items-center justify-between">
              <div>
                <span
                  className={classNames(
                    checked ? 'text-indigo-900' : 'text-gray-900',
                    'block text-sm font-medium'
                  )}
                >
                  {addon.name}
                </span>

                <span
                  className={classNames(
                    checked ? 'text-indigo-700' : 'text-gray-500',
                    'block text-sm'
                  )}
                >
                  {addon.price > 0 ? `Add $${addon.price}` : 'Free'}
                </span>
              </div>
              <div>
                <Field
                  name={SelectionType.A_3}
                  type="checkbox"
                  value={addon.id}
                  onClick={() => handleChange(addon)}
                  className={classNames(
                    checked
                      ? 'border-transparent bg-indigo-600'
                      : 'border-gray-300 bg-white',
                    'mt-0.5 flex h-7 w-7 cursor-pointer items-center justify-center rounded-md border'
                  )}
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
