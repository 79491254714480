import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DiscountContext } from '../providers/discount-provider';
import { Credits, Discount } from '../types';
import {
  CashIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  TagIcon,
} from '@heroicons/react/solid';
import { DiscountKind } from '../constants';

interface Props {
  setCreditsApplied: (value: boolean) => void;
  creditsApplied: boolean;
  coupons: Discount[];
  subsides: Discount[];
  credits: Credits[];
  discountAmount?: number;
}

export default function DiscountSelection({
  setCreditsApplied,
  creditsApplied,
  discountAmount,
  coupons,
  subsides,
  credits,
}: Props) {
  const navigate = useNavigate();
  const [discount] = useContext(DiscountContext);

  const onCouponsClick = () => {
    navigate('/coupons');
  };
  const onSubsidiesClick = () => {
    navigate('/subsidies');
  };
  const onCreditsClick = () => {
    setCreditsApplied(!creditsApplied);
  };

  return (
    <div className="flex flex-col px-6 py-6">
      <div className="mb-6 text-lg font-bold leading-6">Discounts</div>
      <div className="rounded-md border border-gray-200 bg-white shadow-md">
        <div
          onClick={onCouponsClick}
          className="flex cursor-pointer flex-row justify-between border-b border-gray-200 px-2 py-2"
        >
          <div className="flex flex-row items-center gap-1">
            <TagIcon className="h-4 w-4 text-gray-700" />
            <div className="font-medium text-gray-700">Coupons</div>
          </div>
          {(discountAmount ?? 0) > 0 &&
          discount?.couponSubsidy === DiscountKind.Coupon ? (
            <div className="flex flex-row gap-1">
              <div className="rounded bg-green-200 px-2 py-0.5">
                <span className="font-medium text-green-700">
                  -${discountAmount?.toFixed(2)}
                </span>
              </div>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            </div>
          ) : (
            <div className="flex flex-row gap-1">
              {coupons.length > 0 && (
                <div className="text-sm text-green-600">
                  {coupons.length} {coupons.length === 1 ? 'coupon' : 'coupons'}{' '}
                  available
                </div>
              )}
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            </div>
          )}
        </div>
        <div
          onClick={onSubsidiesClick}
          className="flex cursor-pointer flex-row justify-between border-b border-gray-200 px-2 py-2"
        >
          <div className="flex flex-row items-center gap-1">
            <CashIcon className="h-4 w-4 text-gray-700" />
            <div className="font-medium text-gray-700">Subsidies</div>
          </div>
          {(discountAmount ?? 0) > 0 &&
          discount?.couponSubsidy === DiscountKind.Subsidy ? (
            <div className="flex flex-row gap-1">
              <div className="rounded bg-green-200 px-2 py-0.5">
                <span className="font-medium text-green-700">
                  -${discountAmount?.toFixed(2)}
                </span>
              </div>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            </div>
          ) : (
            <div className="flex flex-row gap-1">
              {subsides.length > 0 && (
                <div className="text-sm text-green-600">
                  {subsides.length}{' '}
                  {subsides.length === 1 ? 'subsidy' : 'subsides'} available
                </div>
              )}
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            </div>
          )}
        </div>
        {credits[0] && credits[0].balance && (
          <div
            onClick={onCreditsClick}
            className="flex cursor-pointer flex-row items-center justify-between px-2 py-2"
          >
            <div className="flex flex-row items-center gap-1">
              <CurrencyDollarIcon className="h-4 w-4 text-gray-700" />
              <span className="font-medium text-gray-700">
                Credits (${credits[0].balance})
              </span>
            </div>
            <input
              checked={creditsApplied}
              id="checked-checkbox"
              type="checkbox"
              onChange={onCreditsClick}
              className="h-4 w-4 accent-indigo-600"
            />
          </div>
        )}
      </div>
    </div>
  );
}
