import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { getNodeDataFromEdges } from '../helpers';
import {
  Category,
  Promo,
  QueryResult,
  Product,
  Popups,
  InCartAdditions,
  CreateReview,
  Event,
  UserEvent,
  FeaturedProduct,
} from '../types';

export const CATEGORIES = gql`
  query categories($name: String, $city: String) {
    categories(name: $name) {
      edges {
        node {
          name
          position
          filteredProducts(city: $city) {
            id
            name
            vendor
            totalSize
            availableDates
            availableStartDate
            availableEndDate
            shelfLife
            arrivalDate
            nextArrivalDate
            nextQuantity
            allergens {
              name
            }
            addons {
              id
              name
              description
              selection
              addons {
                id
                name
                price
                description
              }
            }
            vendorObject {
              name
            }
            image
            description
            price
            discountPrice
            discountStartDate
            discountEndDate
            isAvailable
            cutoffTime
            sameDayCutoff
            sameDayQuantity
            cutoffTimeString
            position
            sameDayOrders
            quantity
            prepTime
            nutritionfacts {
              protein
              carbs
              fat
              calories
              servingSize
            }
          }
        }
      }
    }
  }
`;

export const FEATURED_PRODUCTS = gql`
  query featuredProducts($fridge: Int, $date: DateTime) {
    featuredProducts(fridge: $fridge, date: $date) {
      position
      product {
        id
        name
        vendor
        totalSize
        availableDates
        availableStartDate
        availableEndDate
        shelfLife
        arrivalDate
        nextArrivalDate
        nextQuantity
        allergens {
          name
        }
        addons {
          id
          name
          description
          selection
          addons {
            id
            name
            price
            description
          }
        }
        image
        description
        price
        discountPrice
        discountStartDate
        vendorObject {
          name
        }
        discountEndDate
        isAvailable
        cutoffTime
        sameDayCutoff
        sameDayQuantity
        cutoffTimeString
        position
        sameDayOrders
        quantity
        prepTime
        nutritionfacts {
          protein
          carbs
          fat
          calories
          servingSize
        }
      }
      startAt
      endAt
    }
  }
`;

export function useFeaturedProducts(fridge?: number, date?: string) {
  const currFridge = fridge ?? 1;
  const { data, loading, error, refetch } = useQuery<{
    featuredProducts: FeaturedProduct[];
  }>(FEATURED_PRODUCTS, {
    variables: { fridge: currFridge, date },
    skip: !date,
  });
  const featuredProducts = data?.featuredProducts;
  return { featuredProducts, loading, error, refetch };
}

export function useCategoriesWithProducts(name?: string, city?: string) {
  const currCity = city ?? 'Saskatoon';
  const { data, loading, error, refetch } = useQuery<{
    categories: QueryResult<Category>;
  }>(CATEGORIES, { variables: { name, city: currCity } });
  const categories =
    data?.categories.edges && getNodeDataFromEdges(data?.categories.edges);
  return { categories, loading, error, refetch };
}

export async function useLazyCategoriesWithProducts(name?: string) {
  const [query] = useLazyQuery<{
    categories: QueryResult<Category>;
  }>(CATEGORIES, { variables: { name } });
  const { data, loading, error, refetch } = await query({
    variables: { name: name },
  });
  const categories =
    data?.categories.edges && getNodeDataFromEdges(data?.categories.edges);
  return { categories, loading, error, refetch };
}
export const PROMOS = gql`
  query promo($email: String!, $fridge: Int, $date: DateTime) {
    promo(email: $email, fridge: $fridge, date: $date) {
      image
      name
      position
      action
      actionLink
      city
    }
  }
`;

export function usePromos(fridge: number, date: string, email?: string) {
  const { data, loading, error, refetch } = useQuery<{
    promo: Promo[];
  }>(PROMOS, {
    variables: { email, fridge, date },
    skip: !email || !fridge,
  });
  const promos = data?.promo;
  return { promos, loading, error, refetch };
}

export const UPSELLS = gql`
  query incartadditions($city: String) {
    incartadditions(city: $city) {
      active
      id
      price
      product {
        id
        name
        description
        price
        image
      }
    }
  }
`;

export function useUpsells(city: string | undefined) {
  const { data, loading, error, refetch } = useQuery<{
    incartadditions: InCartAdditions[];
  }>(UPSELLS, { variables: { city } });

  const result = data?.incartadditions;
  return { data: result, loading, error, refetch };
}

export const UPSELL_POPUPS = gql`
  query incartpopups($email: String, $products: [Int], $city: String) {
    incartpopups(email: $email, products: $products, city: $city) {
      title
      id
      price
      active
      product {
        id
        name
        image
        description
        cost
        quantity
        sameDayQuantity
        sameDayCutoff
        price
      }
    }
  }
`;

export function useUpsellPopups(
  email: String | undefined,
  products: number[],
  city: string | undefined
) {
  const { data, loading, error, refetch } = useQuery<{
    incartpopups: Popups[];
  }>(UPSELL_POPUPS, {
    variables: { email, products, city },
    skip: !email || !products || !city,
  });
  return { data, loading, error, refetch };
}

export const CREATE_REVIEW = gql`
  mutation createReview($input: ReviewInput!) {
    createReview(input: $input) {
      review {
        id
        booking {
          id
        }
        deliveryRating
        deliveryRatingText
        productRating
        productRatingText
        bookingItem {
          product {
            id
            name
            image
          }
        }
      }
    }
  }
`;

export const UPDATE_REVIEW = gql`
  mutation updateReview($id: Int!, $input: ReviewInput!) {
    updateReview(id: $id, input: $input) {
      review {
        booking {
          id
        }
        deliveryRating
        deliveryRatingText
        productRating
        productRatingText
        bookingItem {
          id
        }
      }
    }
  }
`;

export const GET_REVIEW_PRODUCT = gql`
  query getreviewproduct($booking: Int) {
    getreviewproduct(booking: $booking) {
      id
      product {
        id
        name
        image
      }
    }
  }
`;

export const GET_LAST_EVENT = gql`
  query events($email: String) {
    events(user_Email: $email, last: 1) {
      edges {
        node {
          createdAt
          id
        }
      }
    }
  }
`;
export const GET_PRODUCTS_FROM_CATEGORY = gql`
  query categories($name: String) {
    categories(name: $name) {
      edges {
        node {
          products {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export function useEvents(email?: string) {
  const { data, loading, error, refetch } = useQuery<{
    events: QueryResult<UserEvent>;
  }>(GET_LAST_EVENT, {
    variables: { email: email },
    skip: !email,
  });
  const event = data?.events.edges && getNodeDataFromEdges(data.events.edges);
  return { data: event, loading, error, refetch };
}
