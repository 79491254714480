import { useMutation } from '@apollo/client';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FRIDGE } from '../constants';
import { ScrollToTop } from '../helpers';
import UpsellSkeleton from '../loading-skeletons/upsell-skeleton';
import TopNavigation from '../navigation/top-navigation';
import CheckoutFooter from '../order-summary/checkout-footer';
import DateProductsList from '../order-summary/date-products-list';
import {
  FridgeStatePersistentStorageItem,
  FRIDGE_STATE_KEY,
} from '../persistent-storage/fridge-state.persistent-storage';
import { usePersistentStorageItem } from '../persistent-storage/hooks';
import {
  OrderState,
  OrderStatePersistentStorageItem,
  ORDER_STATE_KEY,
} from '../persistent-storage/order-state.persistent-storage';
import PersistentStorage from '../persistent-storage/persistent-storage';
import {
  GET_PAYMENT_INTENT,
  usePriceTotals,
} from '../services/payment.service';
import { useMe } from '../services/user.service';
import { ProductObj, ProductWithAddons } from '../types';
import IncartPopupItems from '../upsells/incart-popup-items';
import Upsells from '../upsells/upsells';
import { track } from '@amplitude/analytics-browser';

export default function OrderSummaryV2() {
  const { data: me } = useMe();
  const [paymentIntentErrors, setPaymentIntentErrors] = useState<
    { message: string }[]
  >([]);
  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const [fridge, refreshFridgeSelect] = usePersistentStorageItem(
    selectedFridgeState,
    undefined
  );
  const orderState = new OrderStatePersistentStorageItem(ORDER_STATE_KEY);
  const [orderItems, refreshOrderItems] = usePersistentStorageItem(
    orderState,
    []
  );
  const uniqueDates = [...new Set(orderItems.map((e) => e.date))];
  const threeSelected = useRef<Moment[]>();
  threeSelected.current = uniqueDates;
  const convertedUniqueDates = uniqueDates.map((e) => e.toString());

  const productsWithAddons: ProductWithAddons[] = orderItems.map(
    ({ product: { product, addons, incartAddition, incartPopup } }) => {
      // should grab all nested addons ids from product
      const addonGroups = addons.flatMap(({ addons }) => addons);
      const addonIds = addonGroups.map(({ id }) => id);
      return {
        product: product.id,
        addons: addonIds,
        incartAddition: incartAddition ? incartAddition : undefined,
        incartPopup: incartPopup ? incartPopup : undefined,
      };
    }
  );
  const orderDates: string[] = [
    ...new Set(orderItems.map(({ date }) => date.toString() + 'T00:00:00')),
  ];

  const [code, setCode] = useState<string | undefined>();
  const {
    priceTotal,
    loading: priceLoading,
    error,
    refetch,
  } = usePriceTotals(
    productsWithAddons,
    fridge?.id,
    me?.email,
    code,
    orderDates
  );

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [getPaymentIntent] =
    useMutation<{ getPaymentIntentBulk: { clientSecret: string } }>(
      GET_PAYMENT_INTENT
    );

  async function handleGetPaymentIntent() {
    if (!me || !fridge) {
      navigate('/fridges');
      return;
    }

    refreshFridgeSelect();
    const dates = [...new Set(orderItems.map((i) => i.date))];

    let items = [];
    for (const date of dates) {
      const products = orderItems
        .map(({ date: curDate, product }) => {
          const addonGroups = product.addons.flatMap(({ addons }) => addons);
          const addonIds = addonGroups.map(({ id }) => id);
          if (moment(curDate).date() === moment(date).date()) {
            return {
              product: product.product.id,
              addons: addonIds,
              incartAddition: product.incartAddition ?? null,
              incartPopup: product.incartPopup ?? null,
            };
          }
          return null;
        })
        .filter((item) => item);
      items.push({
        date,
        products,
      });
    }

    setLoading(true);
    const input = items.map(({ date, products }) => {
      return {
        payee: me.email,
        fridges: [fridge.id],
        startAt: moment(date).toISOString(true),
        products: products,
        discountCode: code ?? null,
        creditCardDetailsId: null,
      };
    });

    try {
      const { data, errors } = await getPaymentIntent({
        variables: {
          input,
        },
      });
      const clientSecret = data?.getPaymentIntentBulk.clientSecret;
      setLoading(false);
      if (errors || !clientSecret) {
        alert('Error');
        return;
      }
      localStorage.setItem(FRIDGE, JSON.stringify(fridge));
      track('proceed_to_checkout_clicked', {
        cart_quantity: orderItems.length,
        cart_dollar_amount: priceTotal?.subTotal ?? 0,
      });
      navigate(`/checkout/${clientSecret}`);
    } catch (error: any) {
      setLoading(false);
      setPaymentIntentErrors(error.graphQLErrors);
    }
  }

  function removeItem(itemToDelete: OrderState) {
    const filteredOrderItems = orderItems.filter(
      (item) => item !== itemToDelete
    );
    PersistentStorage.update(orderState, filteredOrderItems);
    refreshOrderItems();
    setPaymentIntentErrors([]);
    if (filteredOrderItems.length === 0) {
      navigate('/kitchen');
    }
  }

  function addItemToOrder(
    { product, addons, incartAddition, incartPopup }: ProductObj,
    date: Moment
  ) {
    PersistentStorage.update(orderState, [
      ...orderItems,
      {
        product: {
          product,
          addons,
          incartAddition: incartAddition ? incartAddition : undefined,
          incartPopup: incartPopup ? incartPopup : undefined,
        },
        date: date.toISOString(true).substring(0, 10),
      },
    ]);
    refreshOrderItems();
  }

  const dates = [...new Set(orderItems.map(({ date }) => date))];
  const sortedItems = dates.map((date) => {
    const products = orderItems.filter(
      ({ date: productDate }) => productDate === date
    );
    const addonsPrice = orderItems.flatMap((e) =>
      e.product.addons.flatMap((e) => e.addons.flatMap((e) => e.price))
    );
    return {
      date,
      products,
      addonsPrice,
    };
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const discount = localStorage.getItem('discountCode');
      setCode(discount ? discount : undefined);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      return () => {
        isMounted = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (code) {
      refetch();
    }
  }, [code, refetch]);

  return (
    <>
      <div className="flex min-h-screen flex-col justify-between bg-gray-50">
        <ScrollToTop />
        <div className="mb-auto">
          <TopNavigation title="Your Order" url="/kitchen" />
          {paymentIntentErrors.length >= 1 && (
            <div className="p-6">
              {paymentIntentErrors.map(({ message }) => (
                <div
                  className="relative rounded bg-red-50 px-4 py-3 text-red-700"
                  role="alert"
                >
                  <div className="mr-12 flex flex-row justify-around">
                    <div>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="text-red-500"
                      />
                    </div>
                    <div className="ml-4">
                      <strong className="font-medium">
                        Unavailable Items in Cart
                      </strong>
                      <span className="block sm:inline">{message}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {error &&
            error.graphQLErrors.map(({ message }, i) => (
              <div key={i} className="px-6 pt-2">
                <div
                  className="relative rounded bg-red-50 px-4 py-3 text-red-700"
                  role="alert"
                >
                  {message}
                </div>
              </div>
            ))}
          <div className="flex w-full items-center justify-between px-6 pt-6 pb-2">
            <div className="text-lg font-medium leading-6">Deliveries</div>
            <button
              type="button"
              onClick={() => navigate('/date-select')}
              className="text-sm font-medium text-indigo-600"
            >
              + Add day
            </button>
          </div>
          <DateProductsList removeItem={removeItem} items={sortedItems} />
          {productsWithAddons.length > 0 &&
            me &&
            me?.reminderCounter % 3 === 1 && (
              <IncartPopupItems
                user={me?.email}
                products={orderItems.map((e) => e.product.product.id)}
                dates={convertedUniqueDates}
                addItem={addItemToOrder}
                fridge={fridge ? fridge.location?.city : undefined}
              />
            )}
          {loading || priceLoading ? (
            <>
              <UpsellSkeleton />
            </>
          ) : (
            <>
              <div className="px-4 py-6">
                <Upsells
                  dates={convertedUniqueDates}
                  addItem={addItemToOrder}
                  city={fridge ? fridge.location?.city : undefined}
                />
              </div>
            </>
          )}
        </div>
        <CheckoutFooter
          loading={loading}
          onClick={handleGetPaymentIntent}
          disabled={sortedItems.length === 0 || loading}
          total={priceTotal?.subTotal ?? 0}
        />
      </div>
    </>
  );
}
