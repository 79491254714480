import { gql, useQuery } from '@apollo/client';
import { Credits, Discount, QueryResult } from '../types';
import { Moment } from 'moment';

export const USER_DISCOUNTS = gql`
  query userDiscounts(
    $user: String!
    $date: DateTime!
    $couponSubsidy: String
    $fridge: Int
    $startDates: [DateTime]
    $products: [ProductsAddons]
    $groupOrder: Boolean
    $settings: Boolean
  ) {
    userDiscounts(
      user: $user
      date: $date
      couponSubsidy: $couponSubsidy
      fridge: $fridge
      startDates: $startDates
      products: $products
      groupOrder: $groupOrder
      settings: $settings
    ) {
      edges {
        node {
          id
          code
          title
          description
          kind
          percent
          couponSubsidy
          itemAmount
          itemsApplicablePerOrder
          maximumDiscountAmount
          minimumSpendAmount
          redeemableStartDate
          redeemableEndDate
          redeemableTimes
          redeemableAmount
          redeemedAmountRemaining
          redeemedTimesRemaining
          subsidyBalance
          renewableType
          renewableDayMonth
          renewableDayWeek
          availableStartDate
          availableEndDate
          isAvailable
          oneTimeUse
          isValid
          availableWeekDay
          team
          categories {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export function useCoupons(
  email?: string,
  date?: Moment,
  fridge?: number,
  products?: { product: number; bookingDate: string; addons: number[] }[],
  startDates?: string[],
  groupOrder?: boolean,
  settings?: boolean
) {
  const skip = !email || !fridge || !date;
  const { data, loading, error, refetch, networkStatus } = useQuery<{
    userDiscounts: QueryResult<Discount>;
  }>(USER_DISCOUNTS, {
    variables: {
      user: email,
      date: date?.toISOString().replace('Z', '+00:00'),
      couponSubsidy: 'coupon',
      fridge,
      products,
      startDates,
      groupOrder,
      settings,
    },
    skip,
    fetchPolicy: 'network-only',
  });
  return {
    discounts: data?.userDiscounts,
    loading,
    error,
    refetch,
    networkStatus,
  };
}

export function useSubsidies(
  email?: string,
  date?: Moment,
  fridge?: number,
  products?: { product: number; bookingDate: string; addons: number[] }[],
  startDates?: string[],
  groupOrder?: boolean,
  settings?: boolean
) {
  const skip = !email || !fridge || !date;
  const { data, loading, error, refetch, networkStatus } = useQuery<{
    userDiscounts: QueryResult<Discount>;
  }>(USER_DISCOUNTS, {
    variables: {
      user: email,
      date: date?.toISOString().replace('Z', '+00:00'),
      couponSubsidy: 'subsidy',
      fridge,
      products,
      startDates,
      groupOrder,
      settings,
    },
    skip,
    fetchPolicy: 'network-only',
  });
  return {
    discounts: data?.userDiscounts,
    loading,
    error,
    refetch,
    networkStatus,
  };
}

export const USER_CREDITS = gql`
  query credits($customer_Email: String!) {
    credits(customer_Email: $customer_Email) {
      edges {
        node {
          customer {
            email
          }
          balance
        }
      }
    }
  }
`;

export function useCredits(email?: string) {
  const { data, loading, error, refetch } = useQuery<{
    credits: QueryResult<Credits>;
  }>(USER_CREDITS, {
    variables: {
      customer_Email: email,
    },
    skip: !email,
    fetchPolicy: 'network-only',
  });
  return { credits: data?.credits, loading, error, refetch };
}
