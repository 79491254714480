import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

interface Props {
  title: string;
}

export default function DiscountsHeader({ title }: Props) {
  const navigate = useNavigate();
  return (
    <div className="sticky top-0 z-20">
      <div className="flex justify-between border-b bg-white py-5 px-6 shadow-sm">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="fa-lg w-14 cursor-pointer text-gray-400"
          onClick={() => navigate(-1)}
        />
        <div className="text-base font-medium leading-6 text-gray-900">
          {title}
        </div>
        <div />
      </div>
    </div>
  );
}
