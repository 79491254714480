import moment from 'moment';
import { OrderState } from '../persistent-storage/order-state.persistent-storage';
import ProductCard from './product-card';
import SubtotalCard from './subtotal-card';
import { Fragment } from 'react';

interface Props {
  items: {
    date: moment.Moment;
    products: OrderState[];
    addonsPrice: number[];
  }[];
  removeItem: (item: OrderState) => void;
}

export default function DateProductsList({ items, removeItem }: Props) {
  return (
    <div className="flex flex-col px-6">
      {items.map(({ date, products }, i) => {
        const addonsPrice = products.flatMap((e) =>
          e.product.addons.flatMap((e) => e.addons.flatMap((e) => e.price))
        );
        return (
          <Fragment key={i}>
            <SubtotalCard
              date={date}
              products={products}
              addonsPrice={addonsPrice}
            />
            {products.map((state, i) => {
              const addonsPrice = state.product.addons.flatMap((e) =>
                e.addons.flatMap((e) => e.price)
              );
              return (
                <ProductCard
                  key={i}
                  removeItem={() => removeItem(state)}
                  addonsPrice={addonsPrice}
                  state={state}
                />
              );
            })}
            {/* <div className="mt-7 rounded-lg border border-gray-200" /> */}
          </Fragment>
        );
      })}
    </div>
  );
}
