import { useMemo } from 'react';
import { Discount } from '../types';
import {
  DiscountKind,
  DiscountTeam,
  DiscountType,
  RenewableMonthDay,
  RenewableType,
} from '../constants';
import moment from 'moment';
import { classNames } from '../helpers';

interface Props {
  discount: Discount;
  applied: boolean;
  setApply: (discount: Discount | null) => void;
}

export default function DiscountItem({ discount, applied, setApply }: Props) {
  function handleApply() {
    setApply(applied ? null : discount);
  }

  const details = useMemo(() => {
    const temp = [];
    if (discount.oneTimeUse) {
      temp.push(`\u2022 One time use only`);
    } else if (
      (discount.redeemableAmount &&
        discount.couponSubsidy === DiscountKind.Coupon) ||
      (discount.couponSubsidy === DiscountKind.Subsidy &&
        !discount.redeemableTimes &&
        (discount.redeemedAmountRemaining > 0 || discount.renewableType) &&
        (discount.isValid || (discount?.subsidyBalance ?? 0) > 0))
    ) {
      temp.push(
        <span className="font-medium text-gray-600">
          {`\u2022`}{' '}
          <span className="font-bold text-gray-600">
            ${discount.redeemedAmountRemaining}
          </span>{' '}
          {`remaining ${discount.renewableType ? 'until renewal date' : ''}`}
        </span>
      );
    } else if (
      discount.couponSubsidy === DiscountKind.Subsidy &&
      !discount.isValid &&
      (discount?.subsidyBalance ?? 0) <= 0
    ) {
      temp.push(
        <span className="flex flex-row">
          <span className="font-medium text-yellow-800">{`\u2022`}</span>
          <span className="font-medium text-yellow-800">
            {` Subject to your organization's availability. Please contact your administrator for more details.`}
          </span>
        </span>
      );
    } else if (discount.redeemableTimes) {
      temp.push(
        <span className="font-medium text-gray-600">
          {`\u2022`}{' '}
          <span className="font-bold text-gray-600">
            {discount.redeemedTimesRemaining}
          </span>{' '}
          {`${
            discount.redeemedTimesRemaining === 1 ? 'use' : 'uses'
          } remaining ${discount.renewableType ? 'until renewal date' : ''}`}
        </span>
      );
    }
    if (discount.availableStartDate && discount.availableEndDate) {
      temp.push(
        `\u2022  ${moment(discount.availableStartDate).format(
          'MMMM D, YYYY'
        )} - ${moment(discount.availableEndDate).format('MMMM D, YYYY')}`
      );
    } else if (discount.availableEndDate) {
      temp.push(
        `\u2022 Use by ${moment(discount.availableEndDate).format(
          'MMMM D, YYYY'
        )}`
      );
    }
    if (discount.minimumSpendAmount) {
      temp.push(
        `\u2022 Minimum spend of $${discount.minimumSpendAmount} required`
      );
    }
    if (discount.maximumDiscountAmount) {
      temp.push(
        `\u2022 Maximum discount of $${discount.maximumDiscountAmount}`
      );
    }
    if (discount.kind === DiscountType.BuyXItemsGetYFree) {
      temp.push(
        `\u2022 For items with a value equal to or lower than the highest-priced item`
      );
    }
    if (discount.categories?.edges.length >= 1) {
      temp.push(
        `\u2022 Valid on ${discount.categories.edges
          .filter(({ node }) => node.name.toLowerCase() !== 'featured')
          .map(({ node }) => node.name.toLowerCase())
          .join(', ')} only`
      );
    }
    if (
      discount.renewableDayMonth &&
      discount.renewableType === RenewableType.PerMonth
    ) {
      temp.push(
        `\u2022 Renews on the ${
          discount.renewableDayMonth === RenewableMonthDay.FirstDay
            ? 'first'
            : 'last'
        } day of the month `
      );
    } else if (
      discount.renewableDayWeek &&
      discount.renewableType === RenewableType.PerWeek
    ) {
      temp.push(`\u2022 Renews weekly on ${discount.renewableDayWeek}s`);
    }
    if (discount.itemsApplicablePerOrder) {
      temp.push(
        `\u2022 Max ${discount.itemsApplicablePerOrder} items applicable per order`
      );
    }
    if (discount.availableWeekDay) {
      temp.push(`\u2022 ${discount.availableWeekDay} only`);
    }
    if (discount.team === DiscountTeam.TeamOrderApp) {
      temp.push(`\u2022 Only valid for team orders`);
    }

    return temp;
  }, [discount]);

  return (
    <div className="m-4 rounded-md border border-gray-100 bg-white p-4 shadow-lg">
      <div className="flex flex-row items-center justify-between ">
        <div>
          <div className="text-xs font-medium text-green-600">
            {discount.code}
          </div>
          <div className="text-lg font-medium text-black">{discount.title}</div>
          <div className="text-xs text-gray-500">{discount.description}</div>
        </div>
        <button
          onClick={handleApply}
          className={classNames(
            'h-fit w-fit rounded-md py-1 px-2',
            applied ? 'bg-black text-white' : 'bg-gray-300'
          )}
        >
          <div className="text-xs">{applied ? 'Applied' : 'Apply'}</div>
        </button>
      </div>
      <div className="my-2 h-px w-full bg-gray-200" />
      <div>
        <div className="text-xs font-medium text-gray-600">
          {details.map((detail, index) => (
            <div key={index}>{detail}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
