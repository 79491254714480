import { useEffect } from 'react';
import ErrorPage from '../screens/error';
import { useNavigate } from 'react-router-dom';
import { useMe } from '../services/user.service';
import LoadingPage from '../screens/loading';
import { useSubByEmail } from '../services/subscriptions.service';
import TopNavigation from '../navigation/top-navigation';
import { useCredits } from '../services/discounts.service';
import DiscountUserInfo from './discount-user-info';

export default function BasicUserInfo() {
  const { data: me, loading, refetch } = useMe();
  const navigate = useNavigate();

  const {
    data: subscriptions,
    loading: subLoading,
    error,
  } = useSubByEmail(me?.email);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const { credits } = useCredits(me?.email);

  if (loading) {
    return <LoadingPage />;
  }
  if ((!me && !loading && !subLoading) || error) {
    return <ErrorPage error={'Could not find user'} />;
  }

  return (
    <>
      <TopNavigation title="Your Account" url="/upcoming" />
      <DiscountUserInfo email={me?.email} fridge={0} />

      <div className="p-8">
        <div className="flex flex-col">
          <div className="py-4">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-5 text-gray-500"
            >
              First name
            </label>
            <div className="mt-1 flex justify-between border-b pb-4">
              <p className="text-sm font-medium leading-5 text-gray-900">
                {me?.firstName}
              </p>
              <p
                className="text-sm font-medium leading-5 text-indigo-700"
                onClick={() =>
                  navigate('/edit-profile', {
                    state: { toEdit: 'First Name' },
                  })
                }
              >
                Edit
              </p>
            </div>
          </div>
          <div className="pb-4">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-5 text-gray-500"
            >
              Last name
            </label>
            <div className="mt-1 flex justify-between border-b pb-4">
              <p className="text-sm font-medium leading-5 text-gray-900">
                {me?.lastName}
              </p>
              <p
                className="text-sm font-medium leading-5 text-indigo-700"
                onClick={() =>
                  navigate('/edit-profile', {
                    state: { toEdit: 'Last Name' },
                  })
                }
              >
                Edit
              </p>
            </div>
          </div>
          <div className="pb-4">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-5 text-gray-500"
            >
              Email Address
            </label>
            <div className="mt-1 flex justify-between border-b pb-4">
              <p className="text-sm font-medium leading-5 text-gray-900">
                {me?.email}
              </p>
              <p
                className="text-sm font-medium leading-5 text-indigo-700"
                onClick={() =>
                  navigate('/edit-profile', {
                    state: { toEdit: 'Email' },
                  })
                }
              >
                Edit
              </p>
            </div>
          </div>
          <div className="pb-4">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-5 text-gray-500"
            >
              Phone Number
            </label>
            <div className="mt-1 flex justify-between border-b pb-4">
              <p className="text-sm font-medium leading-5 text-gray-900">
                {me?.phone ? me?.phone : 'Not Set'}
              </p>
              <p
                className="text-sm font-medium leading-5 text-indigo-700"
                onClick={() =>
                  navigate('/edit-profile', {
                    state: { toEdit: 'Phone Number' },
                  })
                }
              >
                Edit
              </p>
            </div>
          </div>
          {/* <div className="pb-4">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-5 text-gray-500"
            >
              Notifications
            </label>
            <div className="mt-1 flex justify-between border-b pb-4">
              <p className="text-sm font-medium leading-5 text-gray-900">
                {'Update notifications'}
              </p>
              <p
                className="text-sm font-medium leading-5 text-indigo-700"
                onClick={() => navigate('/notifications')}
              >
                Edit
              </p>
            </div>
          </div> */}
          {subscriptions && subscriptions[0] && (
            <div className="pb-4">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-5 text-gray-500"
              >
                Saved Payment
              </label>
              <div className="mt-1 flex justify-between border-b pb-4">
                <p className="text-sm font-medium leading-5 text-gray-900">
                  {'Update payment method'}
                </p>
                <p
                  className="text-sm font-medium leading-5 text-indigo-700"
                  onClick={() => navigate('/credit-card')}
                >
                  Edit
                </p>
              </div>
            </div>
          )}

          {/* <div className="sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-gray-700"
            >
              Last name
            </label>
            <div className="mt-1">
              <input
                onBlur={({ target: { value } }) =>
                  handleChangeUser({ ...me, lastName: value })
                }
                type="text"
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                defaultValue={me.lastName}
                placeholder={me.lastName ? undefined : 'Last name not set'}
                className="block w-full rounded-sm border-gray-500 py-3 px-2 shadow-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div> */}
          <div className="pb-4">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-5 text-gray-500"
            >
              Credits
            </label>
            <div className="mt-1 flex justify-between border-b pb-4">
              <p className="text-sm font-medium leading-5 text-gray-900">
                ${credits?.edges[0] ? credits?.edges[0].node?.balance : '0.00'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
