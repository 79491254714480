import moment, { Moment } from 'moment';
import { Day, utils } from 'react-modern-calendar-datepicker';
import fillerImg from './img/filler-img.png';
import { Booking, IEdge, Fridge, Product } from './types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SelectionType } from './constants';

export function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}

export const getNodeDataFromEdges = <T,>(edges: IEdge<T>[]): T[] => {
  return edges.map(({ node }) => node);
};

export function getSelectionType(type: SelectionType) {
  switch (type) {
    case SelectionType.A_1:
      return 'Select 1';
    case SelectionType.A_2:
      return 'Select 2 - 3';
    case SelectionType.A_3:
      return 'Select as many as you like';
    default:
      return 'Unknown';
  }
}

export const useCloudinaryImage = (image?: string): string => {
  if (!image) {
    return fillerImg;
  }
  return `${process.env.REACT_APP_CLOUDINARY_URL}${image}`;
};

export function getDaysInMonth(month: number, year: number) {
  return new Date(year, month + 1, 0).getDate();
}

export class DateStream {
  private dates: Moment[];
  private index = 0;

  public constructor(dates: Moment[]) {
    this.dates = dates;
  }

  public next() {
    ++this.index;
    return this;
  }

  public previous() {
    --this.index;
    return this;
  }

  public slice(): [Moment | undefined, Moment, Moment | undefined] {
    return [
      this.dates[this.index - 1],
      this.dates[this.index],
      this.dates[this.index + 1],
    ];
  }

  public clone(): DateStream {
    const ds = new DateStream(this.dates);
    ds.index = this.index;
    return ds;
  }
}

export function usePluralCount(singular: string, length?: number) {
  if (length === 0 || !length) {
    return `${length} ${singular}s`;
  }
  return length <= 1 ? `${length} ${singular}` : `${length} ${singular}s`;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default class Money {
  private amount: number;

  public constructor(amount: number) {
    this.amount = amount;
  }

  public stripe() {
    return Math.floor(this.amount * 100);
  }
}

export function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  const R = 6371; // km
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const rad1 = toRadians(lat1);
  const rad2 = toRadians(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(rad1) * Math.cos(rad2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

// Converts numeric degrees to radians
function toRadians(value: number) {
  return (value * Math.PI) / 180;
}

//makes the end date one month after the start + until the end of the week.
export function handleMaxDate() {
  let today = utils('en').getToday();
  let dayOfWeek = parseInt(moment().format('d'));
  let totalDays = 28 + (5 - dayOfWeek);
  let endOfMonth = utils('en').getMonthLength(today);
  let daysRemaining = endOfMonth - today.day;
  let result = totalDays - daysRemaining;
  today.day = result;
  today.month = today.month + 1;
  return today;
}

//Calculates weekend days
export function handleWeekends(
  firstDate: number,
  monthLength: number,
  today: Day
) {
  let weekendDates = [];
  //conditionally chooses the array, also stores values of the array that overcap, so when it shifts in the second array the offset calculates the last few days
  if (firstDate === 0) {
    weekendDates = [1, 7, 8, 14, 15, 21, 22, 28, 29];
  } else if (firstDate === 1) {
    weekendDates = [6, 7, 13, 14, 20, 21, 27, 28, 34];
  } else if (firstDate === 2) {
    weekendDates = [5, 6, 12, 13, 19, 20, 26, 27, 33, 34];
  } else if (firstDate === 3) {
    weekendDates = [4, 5, 11, 12, 18, 19, 25, 26, 32, 33];
  } else if (firstDate === 4) {
    weekendDates = [3, 4, 10, 11, 17, 18, 24, 25, 31, 32];
  } else if (firstDate === 5) {
    weekendDates = [2, 3, 9, 10, 16, 17, 23, 24, 30, 31];
  } else {
    weekendDates = [1, 2, 8, 9, 15, 16, 22, 23, 29, 30];
  }
  const weekends: Day[] = weekendDates.map((value: number) => ({
    day: value,
    month: today.month,
    year: today.year,
  }));
  //shifts the array to the values for the next month depending on if it's a 28,29,30, or 31 day month
  if (monthLength === 28) {
  } else if (monthLength === 29) {
    weekendDates = weekendDates.map((x) => x - 1);
  } else if (monthLength === 30) {
    weekendDates = weekendDates.map((x) => x - 2);
  } else {
    weekendDates = weekendDates.map((x) => x - 3);
  }
  if (today.month === 12) {
    const nextMonthWeekends: Day[] = weekendDates.map((value: number) => ({
      day: value,
      month: 1,
      year: today.year + 1,
    }));
    return weekends.concat(nextMonthWeekends);
  } else {
    const nextMonthWeekends: Day[] = weekendDates.map((value: number) => ({
      day: value,
      month: today.month + 1,
      year: today.year,
    }));

    return weekends.concat(nextMonthWeekends);
  }
}

//disabled days is a function that disables today, and disables tomorrow conditionally if the local time is 5:50pm or later
//it passes what day of the week it is to handleWeekends that calculates what days of the month need to be disabled for weekends to be disabled
export function disabledDays(datesFromBackend?: Day[], city?: string) {
  let date = new Date();
  let today = utils('en').getToday();
  let monthLength = utils('en').getMonthLength(today);
  let firstDate = utils('en').getMonthFirstWeekday(today);
  const weekends = handleWeekends(firstDate, monthLength, today);
  // const todayCutoffTime =
  //   moment(date).hour() < 8 ||
  //   (moment(date).hour() === 8 && moment(date).minutes() < 30);
  // let tomorrow: Day = {
  //   day: today.day,
  //   month: today.month,
  //   year: today.year,
  // };
  // if (today.day + 1 > monthLength) {
  //   tomorrow.month = tomorrow.month + 1;
  //   tomorrow.day = 1;
  // } else {
  //   tomorrow.day = tomorrow.day + 1;
  // }
  // if (date.getHours() >= 18) {
  //   if (datesFromBackend && todayCutoffTime) {
  //     return weekends.concat([tomorrow], datesFromBackend);
  //   } else if (datesFromBackend) {
  //     return weekends.concat([today], [tomorrow], datesFromBackend);
  //   }
  //   if (todayCutoffTime) {
  //     return weekends.concat([tomorrow]);
  //   } else {
  //     return weekends.concat([today], [tomorrow]);
  //   }
  // }
  if (city && city === 'Calgary' && datesFromBackend) {
    return weekends.concat([today], datesFromBackend);
  } else if (city && city === 'Calgary') {
    return weekends.concat([today]);
  }
  if (datesFromBackend) {
    return weekends.concat([today], datesFromBackend);
  }
  return weekends.concat([today]);
}

export function groupBookingsBy(booking: Booking[]) {
  var vals: Booking[][] = [[]];
  var temp: Booking[] = [];
  if (booking.length === 1) {
    vals = [booking];
    return vals;
  }
  booking.forEach((e, index, array) => {
    const string = e.startAt.substring(0, 10);
    //Handle initial value
    if (index === 0) {
      temp = [e];
      //handle end value if its the same as previous
    } else if (
      index === booking.length - 1 &&
      string === array[index - 1].startAt.substring(0, 10)
    ) {
      temp = [...temp, e];
      vals = [...vals, temp];
      //handle end value if it's not the same as previous
    } else if (index === booking.length - 1) {
      vals = [...vals, temp, [e]];
      //add to array if date is the same
    } else if (
      array[index - 1] &&
      string === array[index - 1].startAt.substring(0, 10)
    ) {
      temp = [...temp, e];
      //reset array and add the array if it's not the same value
    } else if (
      array[index - 1] &&
      string !== array[index - 1].startAt.substring(0, 10)
    ) {
      vals = [...vals, temp];
      temp = [e];
    }
  });
  return vals.slice(1);
}

export function handleDay(dayChanged: string) {
  if (dayChanged) {
    switch (dayChanged) {
      case 'Sunday':
        return '6';
      case 'Monday':
        return '0';
      case 'Tuesday':
        return '1';
      case 'Wednesday':
        return '2';
      case 'Thursday':
        return '3';
      case 'Friday':
        return '4';
      case 'Saturday':
        return '5';
    }
  }
}

export function handleBackendReminderDays(days: string | undefined) {
  if (days) {
    if (days === 'A_6') {
      return 'Sunday';
    } else if (days === 'A_0') {
      return 'Monday';
    } else if (days === 'A_1') {
      return 'Tuesday';
    } else if (days === 'A_2') {
      return 'Wednesday';
    } else if (days === 'A_3') {
      return 'Thursday';
    } else if (days === 'A_4') {
      return 'Friday';
    } else if (days === 'A_5') {
      return 'Saturday';
    } else {
      return 'empty';
    }
  }
  return 'empty';
}

export function validatePhoneNumber(value: string): string {
  if (!value) {
    return 'Required';
  } else if (
    !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i.test(
      value
    )
  ) {
    return 'Invalid Phone Number';
  } else {
    return '';
  }
}

export function validateEmail(value: string): string {
  if (!value) {
    return 'Required';
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      value
    )
  ) {
    return 'Invalid Email';
  } else {
    return '';
  }
}

export function validatePassword(value: string): string {
  if (!value) {
    return 'Required';
  } else if (value.length < 8) {
    return 'Password Must be at least 8 characters';
  } else if (/^(.)\1+$/.test(value) || value === 'password') {
    return 'Password is too generic';
  } else if (!isNaN(Number(value))) {
    return 'Password is entirely numeric';
  } else {
    return '';
  }
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function handleAdditionalDirections(fridge: Fridge) {
  const details = fridge?.location?.additionalDirections;
  if (details === 'A_1') {
    return '';
  } else if (details === 'A_2') {
    return 'Next to';
  } else if (details === 'A_3') {
    return 'Near';
  } else if (details === 'A_4') {
    return 'Across from';
  } else if (details === 'A_5') {
    return 'Beside';
  } else if (details === 'A_6') {
    return 'Behind';
  } else if (details === 'A_7') {
    return 'Inside';
  }
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function handleReformatMonth(selectedMonth: number) {
  let month = '';
  switch (selectedMonth) {
    case 1:
      month = 'January';
      break;
    case 2:
      month = 'February';
      break;
    case 3:
      month = 'March';
      break;
    case 4:
      month = 'April';
      break;
    case 5:
      month = 'May';
      break;
    case 6:
      month = 'June';
      break;
    case 7:
      month = 'July';
      break;
    case 8:
      month = 'August';
      break;
    case 9:
      month = 'September';
      break;
    case 10:
      month = 'October';
      break;
    case 11:
      month = 'November';
      break;
    case 12:
      month = 'December';
      break;
    default:
      month = 'January';
  }
  return month;
}

export enum DeviceType {
  Android,
  iOS,
  Browser,
}

export function getMobileOperatingSystem() {
  // @ts-ignore
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return DeviceType.Android;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return DeviceType.iOS;
  }

  return DeviceType.Browser;
}

export function handleCarouselCity(city: string) {
  let returnCity = '';
  switch (city) {
    case 'A_1':
      returnCity = 'Saskatoon';
      break;
    case 'A_2':
      returnCity = 'Calgary';
      break;
    default:
      returnCity = 'Saskatoon';
  }
  return returnCity;
}

export function valideEmailBoolean(value: string): boolean {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  return emailRegex.test(value);
}

export function validPhoneBoolean(value: string): boolean {
  const phoneRegex =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i;
  return phoneRegex.test(value);
}

export function formatPhoneNumber(text: string, previousText?: string) {
  if (!text) return text;

  const deleting = previousText && previousText.length > text.length;
  if (deleting) {
    return text;
  }

  let cleaned = text.replace('+1 ', '').replace(/\D/g, ''); // remove non-digit characters
  let match = null;

  if (cleaned.length > 0 && cleaned.length < 5) {
    return `+1 (${cleaned}`;
  } else if (cleaned.length == 3) {
    return `+1 (${cleaned}) `;
  } else if (cleaned.length > 3 && cleaned.length < 5) {
    match = cleaned.match(/(\d{3})(\d{1,3})$/);
    if (match) {
      return `+1 (${match[1]}) ${match[2]}`;
    }
  } else if (cleaned.length == 6) {
    match = cleaned.match(/(\d{3})(\d{3})$/);
    if (match) {
      return `+1 (${match[1]}) ${match[2]}-`;
    }
  } else if (cleaned.length > 6) {
    match = cleaned.match(/(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
    }
  }
  return text;
}

export function isDiscountPriceValid(product: Product, date: Moment): boolean {
  if (product.discountPrice) {
    if (!product.discountStartDate || !product.discountEndDate) {
      return true;
    }
    const discountStart = moment(product.discountStartDate);
    const discountEnd = moment(product.discountEndDate);
    if (date.isBetween(discountStart, discountEnd, 'day', '[]')) {
      return true;
    }
  }
  return false;
}
