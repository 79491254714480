import DiscountsHeader from '../../discounts/discounts-header';
import moment from 'moment';
import {
  FridgeStatePersistentStorageItem,
  FRIDGE_STATE_KEY,
} from '../../persistent-storage/fridge-state.persistent-storage';
import PersistentStorage from '../../persistent-storage/persistent-storage';
import { useCoupons } from '../../services/discounts.service';
import { useMe } from '../../services/user.service';
import DiscountItem from '../../discounts/discount-item';
import { useContext } from 'react';
import { DiscountContext } from '../../providers/discount-provider';
import { usePersistentStorageItem } from '../../persistent-storage/hooks';
import {
  ORDER_STATE_KEY,
  OrderStatePersistentStorageItem,
} from '../../persistent-storage/order-state.persistent-storage';

const now = moment();
export default function Coupons() {
  const orderState = new OrderStatePersistentStorageItem(ORDER_STATE_KEY);
  const [orderItems] = usePersistentStorageItem(orderState, []);
  const [discount, setDiscount] = useContext(DiscountContext);
  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const fridge = PersistentStorage.get(selectedFridgeState);
  const { data: me } = useMe();
  const products = orderItems.map((item) => ({
    product: +item.product.product.id,
    addons: item.product.addons.map((addon) => +addon.id),
    bookingDate: moment(item.date).toISOString(),
  }));

  const { discounts, loading } = useCoupons(
    me?.email,
    now,
    fridge?.id,
    products,
    undefined,
    false,
    false
  );

  if (loading) {
    return null;
  }
  return (
    <div>
      <DiscountsHeader title="Coupons" />
      {!loading &&
        discounts?.edges.map(({ node }, i) => (
          <DiscountItem
            key={i}
            applied={discount?.id === node.id}
            setApply={setDiscount}
            discount={node}
          />
        ))}
    </div>
  );
}
