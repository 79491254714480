import { useMutation } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { GraphQLError } from 'graphql';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CheckoutForm from '../checkout/checkout-form';
import CheckoutHeader from '../checkout/checkout-header';
import OrderItemSummary from '../checkout/order-items-summary';
import PriceTotals from '../checkout/price-totals';
import Alert, { AlertType } from '../components/alert';
import { classNames, ScrollToTop } from '../helpers';
import {
  FridgeStatePersistentStorageItem,
  FRIDGE_STATE_KEY,
} from '../persistent-storage/fridge-state.persistent-storage';
import { usePersistentStorageItem } from '../persistent-storage/hooks';
import {
  OrderStatePersistentStorageItem,
  ORDER_STATE_KEY,
} from '../persistent-storage/order-state.persistent-storage';
import PersistentStorage from '../persistent-storage/persistent-storage';
import {
  DISCOUNT_ORDER_VERIFY,
  GET_PAYMENT_INTENT,
  usePriceTotalsWithDiscount,
} from '../services/payment.service';
import { CHANGE_USER, useMe } from '../services/user.service';
import { ProductWithAddons } from '../types';
import ErrorPage from './error';
import LoadingPage from './loading';
import { track } from '@amplitude/analytics-browser';
import DiscountSelection from '../checkout/discount-selection';
import { DiscountContext } from '../providers/discount-provider';
import {
  useCoupons,
  useCredits,
  useSubsidies,
} from '../services/discounts.service';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK!);
const now = moment();

export default function CheckoutV4() {
  const navigate = useNavigate();
  const [discount, setDiscount] = useContext(DiscountContext);
  const [creditsApplied, setCreditsApplied] = useState(false);
  const { clientSecret } = useParams();
  const [discountOrderVerify] = useMutation(DISCOUNT_ORDER_VERIFY);
  const [submitLoading, setSubmitLoading] = useState(false);
  const orderState = new OrderStatePersistentStorageItem(ORDER_STATE_KEY);
  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const [selectedFridge, refreshFridgeSelect] = usePersistentStorageItem(
    selectedFridgeState,
    undefined
  );
  const discountCode = localStorage.getItem('discountCode') ?? undefined;
  const [orderItems] = usePersistentStorageItem(orderState, []);
  const { data: me, loading, refetch } = useMe();
  const [paymentIntentBulkError, setPaymentIntentBulkError] =
    useState<string>();
  const productsWithAddons: ProductWithAddons[] = orderItems.map(
    ({ product }) => {
      const addonGroups = product.addons.flatMap(({ addons }) => addons);
      const addonIds = addonGroups.map(({ id }) => id);
      return {
        product: product.product.id,
        addons: addonIds,
        incartAddition: product.incartAddition
          ? product.incartAddition
          : undefined,
        incartPopup: product.incartPopup ? product.incartPopup : undefined,
      };
    }
  );
  const startDates = orderItems.map(({ date }) => date + 'T00:00.000');
  const {
    priceTotal,
    error,
    loading: priceLoading,
  } = usePriceTotalsWithDiscount(
    productsWithAddons,
    selectedFridge?.id,
    me?.email,
    discount?.code,
    creditsApplied,
    startDates
  );

  const products = orderItems.map((item) => ({
    product: +item.product.product.id,
    addons: item.product.addons.map((addon) => +addon.id),
    bookingDate: moment(item.date).toISOString(),
  }));

  const { discounts: coupons } = useCoupons(
    me?.email,
    now,
    selectedFridge?.id,
    products,
    undefined,
    false,
    false
  );
  const { discounts: subsidies } = useSubsidies(
    me?.email,
    now,
    selectedFridge?.id,
    products,
    undefined,
    false,
    false
  );

  const { credits } = useCredits(me?.email);

  const dates = [...new Set(orderItems.map(({ date }) => date))];

  const [updateUser] = useMutation<{ lastName: string }>(CHANGE_USER);
  const [updateUserError, setUpdateUserError] = useState<string | null>();
  const [getPaymentIntent] = useMutation<{
    getPaymentIntentBulk: { clientSecret: string };
  }>(GET_PAYMENT_INTENT);

  const sortedItems = dates.map((date) => {
    const products = orderItems.filter(
      ({ date: productDate }) => productDate === date
    );
    return {
      date,
      products,
    };
  });
  useEffect(() => {
    handleGetPaymentIntent();
  }, [discount]);

  if (loading || priceLoading) {
    return <LoadingPage />;
  }

  if (!me || !priceTotal || !clientSecret) {
    return (
      <ErrorPage
        error={error?.graphQLErrors.map(({ message }) => `${message}`)}
      />
    );
  }

  async function submitAndVerifyDiscountedOrder() {
    if (!clientSecret) {
      return;
    }
    try {
      setSubmitLoading(true);
      track('submit_order_clicked', {
        order_quantity: sortedItems
          .map(({ products }) => products.length)
          .reduce((a, b) => a + b, 0),
        subtotal_amount: priceTotal?.subTotal,
        discount_amount: priceTotal?.discountTotal,
        multi_day_discount_amount: priceTotal?.multiDayDiscount,
        taxes: priceTotal?.taxTotal,
        delivery: 0,
        items: orderItems.map((item) => item.product.product.name),
        booking_days: sortedItems.length,
      });
      const { data } = await discountOrderVerify({
        variables: { input: { uuid: clientSecret } },
      });
      setSubmitLoading(false);
      try {
        const { data, errors } = await updateUser({
          variables: {
            id: me?.pk,
            input: {
              reminderCounter: me ? me?.reminderCounter + 1 : 1,
            },
          },
        });
        if (!data || errors) {
          setUpdateUserError('An Error occurred updating reminderCounter');
          return;
        }
      } catch (e) {
        console.log(e);
        return;
      }
      if (data) {
        setDiscount(null);
        PersistentStorage.clear(
          new OrderStatePersistentStorageItem(ORDER_STATE_KEY)
        );
        track('order_procesed', {
          order_quantity: sortedItems
            .map(({ products }) => products.length)
            .reduce((a, b) => a + b, 0),
          subtotal_amount: priceTotal?.subTotal,
          discount_amount: priceTotal?.discountTotal,
          multi_day_discount_amount: priceTotal?.multiDayDiscount,
          taxes: priceTotal?.taxTotal,
          delivery: 0,
          items: orderItems.map((item) => item.product.product.name),
          booking_days: sortedItems.length,
        });
        if (me && !me?.receiveReminders) {
          if (me?.reminderCounter % 3 === 0) {
            navigate(`/reminders/${clientSecret}`);
          } else {
            navigate(`/order-success/${clientSecret}`);
          }
        } else {
          navigate(`/order-success/${clientSecret}`);
        }
      }
    } catch (err) {
      setSubmitLoading(false);
      alert(err);
    }
  }

  async function handleGetPaymentIntent() {
    if (!me || !selectedFridge) {
      navigate('/fridges');
      return;
    }

    refreshFridgeSelect();
    const dates = [...new Set(orderItems.map((i) => i.date))];
    // const datesConverted = orderItems.map((e) => e.date + 'T00:00.000Z');

    let items = [];
    for (const date of dates) {
      const products = orderItems
        .map(({ date: curDate, product }) => {
          const addonGroups = product.addons.flatMap(({ addons }) => addons);
          const addonIds = addonGroups.map(({ id }) => id);
          if (moment(curDate).date() === moment(date).date()) {
            return {
              product: product.product.id,
              addons: addonIds,
              incartAddition: product.incartAddition
                ? product.incartAddition
                : undefined,
              incartPopup: product.incartPopup
                ? product.incartPopup
                : undefined,
            };
          }
          return null;
        })
        .filter((item) => item);
      items.push({
        date,
        products,
      });
    }

    const input = items.map(({ date, products }) => {
      return {
        payee: me.email,
        fridges: [selectedFridge.id],
        startAt: moment(date).toISOString(true),
        products,
        discountCode: discount?.code ?? null,
        creditCardDetailsId: null,
        useCredits: creditsApplied,
      };
    });
    try {
      const { data, errors } = await getPaymentIntent({
        variables: {
          input,
        },
      });
      const newClientSecret = data?.getPaymentIntentBulk.clientSecret;
      if (errors || !newClientSecret) {
        setPaymentIntentBulkError(
          'An error occurred getting the payment intent. Please try again.'
        );
        return;
      }
      navigate(`/checkout/${newClientSecret}`, { replace: true });
    } catch (error: any) {
      const errorMessage = (error as GraphQLError).message;
      setPaymentIntentBulkError(errorMessage);
    }
  }

  const paymentIntentExists = clientSecret?.startsWith('pi_');
  return (
    <>
      <ScrollToTop />
      <CheckoutHeader />
      <OrderItemSummary priceTotal={priceTotal} items={sortedItems} />
      {priceTotal.error && (
        <div className="px-4 pt-4">
          <div className="flex h-[72px] bg-yellow-50">
            <div className="flex flex-row px-4 py-4">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="flex items-center justify-start text-yellow-400"
              />
              <p className="items-center justify-center px-2 text-sm text-yellow-800">
                {priceTotal.error}
              </p>
            </div>
          </div>
        </div>
      )}
      {updateUserError && (
        <Alert
          type={AlertType.Error}
          title="Update Error"
          messages={[updateUserError]}
        />
      )}
      {paymentIntentBulkError && (
        <div className="px-4 pt-4">
          <div className="flex h-[72px] bg-yellow-50">
            <div className="flex flex-row items-center px-4 py-4">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="flex items-center justify-start text-center text-yellow-400"
              />
              <p className="flex items-center justify-center px-2 text-sm text-yellow-800">
                {paymentIntentBulkError}
              </p>
            </div>
          </div>
        </div>
      )}
      <DiscountSelection
        coupons={coupons?.edges.map(({ node }) => node) ?? []}
        subsides={subsidies?.edges.map(({ node }) => node) ?? []}
        credits={credits?.edges.map(({ node }) => node) ?? []}
        creditsApplied={creditsApplied}
        setCreditsApplied={setCreditsApplied}
        discountAmount={priceTotal.discountTotal}
      />
      <div className="px-6">
        <PriceTotals checkout priceTotal={priceTotal} code={discountCode} />
      </div>
      <footer className="bg-white">
        {paymentIntentExists ? (
          <div className="px-4">
            <Elements stripe={stripe}>
              <CheckoutForm
                total={priceTotal.total}
                user={me}
                clientSecret={clientSecret}
                handleGetPaymentIntent={handleGetPaymentIntent}
                defaultCard={me.defaultSelectedCard}
                refetchUser={refetch}
              />
            </Elements>
          </div>
        ) : (
          <div className="mt-8 mb-6 px-4">
            <button
              onClick={submitAndVerifyDiscountedOrder}
              type="button"
              disabled={loading}
              className={classNames(
                me.defaultSelectedCard
                  ? 'bg-indigo-600'
                  : 'bg-black hover:bg-gray-800',
                'w-full rounded-lg border border-transparent  py-3 px-4 text-base font-medium text-white shadow-sm  '
              )}
            >
              {submitLoading ? 'Submitting...' : 'Submit Order'}
            </button>
          </div>
        )}
      </footer>
    </>
  );
}
