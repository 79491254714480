import { useMutation } from '@apollo/client';
import {
  faBars,
  faHeadset,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BottomSheetModal from '../components/bottom-sheet-modal';
import DeliveryResults from '../components/delivery-results';
import { REFRESH_TOKEN, TOKEN } from '../constants';
import FeedbackMealModal from '../feedback/feedback-meal-modal';
import {
  classNames,
  DeviceType,
  getMobileOperatingSystem,
  groupBookingsBy,
} from '../helpers';
import { useModalToggle } from '../hooks';
import logo from '../img/logo-white.png';
import {
  FridgeStatePersistentStorageItem,
  FRIDGE_STATE_KEY,
} from '../persistent-storage/fridge-state.persistent-storage';
import PersistentStorage from '../persistent-storage/persistent-storage';
import { useBookings } from '../services/orders.service';
import {
  UPDATE_SUBSCRIPTION,
  useSubByEmail,
} from '../services/subscriptions.service';
import { useMe } from '../services/user.service';
import MealPlanTab from '../subscriptions/meal-plan-tab';
import { Booking, SubscriptionReturnType } from '../types';
import ErrorPage from './error';
import LoadingPage from './loading';
import PrestockTooltipModal from '../pre-stock/prestock-tooltip-modal';
import calendar from '../img/calendar.png';
import { track } from '@amplitude/analytics-browser';
import jwtDecode from 'jwt-decode';
import { JWT } from '../types';

const navigation = [
  { name: 'Your Deliveries', href: '/upcoming', current: true },
  { href: '/fridges', current: false },
];

enum DeliveryTab {
  Past = 'Past',
  Today = 'Today',
  Upcoming = 'Upcoming',
}

const appID = 1644786730;

interface state {
  fromMealPayment?: boolean;
}
export default function Deliveries() {
  const { data: me, loading: userLoading, refetch } = useMe();
  const { tab } = useParams();

  const location = useLocation();
  const reviewModal = useModalToggle();
  const visible = reviewModal.props.visible;
  const [reviewToggled, setReviewToggled] = useState(false);

  const [prestockOpen, setPrestockOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const device: DeviceType = getMobileOperatingSystem();
  function handleLink() {
    track('gng_clicked');
    if (device === DeviceType.Android) {
      return 'https://play.google.com/store/apps/details?id=com.cubbi';
    } else {
      return 'https://apps.apple.com/ca/app/cubbi-2-0/id6444135609';
    }
  }

  const state = location.state as state;
  useEffect(() => {
    refetch();
  }, [refetch]);
  const navigate = useNavigate();
  const selectedFridgeState = new FridgeStatePersistentStorageItem(
    FRIDGE_STATE_KEY
  );
  const fridge = PersistentStorage.get(selectedFridgeState);
  const today = moment();
  const todayString = today.toISOString(true).substring(0, 10);
  const [updateSubscription] =
    useMutation<{ updateSubscription: SubscriptionReturnType }>(
      UPDATE_SUBSCRIPTION
    );
  const { bookings, error, loading } = useBookings(me?.email);
  const { data: subscriptions, refetch: refetchSub } = useSubByEmail(me?.email);
  const [closePrestockPrompt, setClosePrestockPrompt] = useState(false);

  useEffect(() => {
    localStorage.removeItem('discountCode');
  }, []);

  //sort fridges by ascending order
  const sortedBookings = bookings?.sort(({ startAt: a }, { startAt: b }) =>
    a.localeCompare(b)
  );

  const todayOrders = sortedBookings?.filter(
    ({ startAt }) => startAt.substring(0, 10) === todayString
  );

  const upcomingOrders = sortedBookings?.filter(
    ({ startAt }) => todayString < startAt.substring(0, 10)
  );

  let pastOrders = sortedBookings?.filter(
    ({ startAt }) => todayString > startAt.substring(0, 10)
  );
  pastOrders = pastOrders
    ? pastOrders.sort((a, b) => b.startAt.localeCompare(a.startAt))
    : [];

  function groupByFridge(orders: Booking[]) {
    const names = orders?.map(({ cubbi }) => cubbi.fridge.name);
    const filtered = orders?.filter(
      ({ cubbi }, index) => !names.includes(cubbi.fridge.name, index + 1)
    );
    return filtered;
  }
  const [selectedTab, setSelectedTab] = useState(DeliveryTab.Today);

  function groupByDateFridge(orders: Booking[]) {
    const names = orders?.map(
      ({ cubbi, startAt }) => cubbi.fridge.name + startAt.substring(0, 10)
    );
    const filtered = orders?.filter(
      ({ cubbi, startAt }, index) =>
        !names?.includes(
          cubbi.fridge.name + startAt.substring(0, 10),
          index + 1
        )
    );
    return filtered;
  }

  const todaysGroupedFridges = groupByFridge(todayOrders ? todayOrders : []);
  const pastGroupedByDates = groupBookingsBy(pastOrders ? pastOrders : []);
  const upcomingGroupedByDates = groupBookingsBy(
    upcomingOrders ? upcomingOrders : []
  );

  useEffect(() => {
    const appModalClosed = localStorage.getItem('appModalClosed');
    if (!appModalClosed) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  //prestockPrompt popup for users that have selected a pod that is designated for the beta
  useEffect(() => {
    if (me && me.prestockPrompt && !closePrestockPrompt) {
      if (fridge) {
        if (fridge.prestock) {
          setPrestockOpen(true);
          setClosePrestockPrompt(true);
        }
      }
    }
  }, [me]);

  if (error) {
    return <ErrorPage />;
  }

  if (loading || userLoading) {
    return <LoadingPage />;
  }
  if (
    me &&
    !loading &&
    (!me.firstName || !me.lastName || me.email.includes('cubbi_temp_'))
  ) {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    navigate('/onboarding');
    return <></>;
  }
  const token = localStorage.getItem(TOKEN);
  if (token) {
    const { exp }: JWT = jwtDecode(token!);
    if (Math.floor(Date.now() / 1000) >= exp + 604500) {
      navigate('/onboarding');
      return <></>;
    }
  }
  function handleLogout() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    navigate('/onboarding');
  }

  function handleNavigation() {
    track('preorder_clicked');
    if (!fridge) {
      navigate('/fridge-select');
      return;
    }
    navigate('/date-select', { state: { fromDashboard: true } });
  }

  function handlePrestock() {
    if (fridge) {
      if (fridge.prestock) {
        navigate('/prestock-menu');
      } else {
        navigate('/prestock-fridges');
      }
    } else {
      navigate('/prestock-fridges');
    }
  }

  function handleChangeTab(title: DeliveryTab) {
    console.log(title.toLowerCase() + '_tab_clicked');
    track(title.toLowerCase() + '_tab_clicked');
    setSelectedTab(title);
  }

  return (
    <>
      <div className="mb-4 block">
        <div>
          <div className="bg-gray-800 pb-32">
            <Disclosure as="nav" className="bg-gray-800">
              {({ open }) => (
                <>
                  <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="border-b border-gray-700">
                      <div className="flex h-16 items-center justify-between px-4 sm:px-0">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <img className="h-8" src={logo} alt="cubbi" />
                          </div>
                          <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                              {navigation.map((item, i) => (
                                <a
                                  key={i}
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-900 text-white'
                                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                    'rounded-md px-3 py-2 text-sm font-medium'
                                  )}
                                  aria-current={
                                    item.current ? 'page' : undefined
                                  }
                                >
                                  {item.name}
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="hidden md:block">
                          <div className="relative ml-4 flex items-center md:ml-6">
                            <Menu as="div" className="pr-2 text-gray-400">
                              <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm">
                                <FontAwesomeIcon
                                  icon={faHeadset}
                                  size="lg"
                                  className="text-gray-400"
                                  aria-hidden="true"
                                  onClick={() => {
                                    track('homepage_help_button_clicked');
                                  }}
                                />
                              </Menu.Button>
                              <Menu.Items className="absolute right-8 mt-2 w-28 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <Menu.Item key="call">
                                  <a
                                    target="__blank"
                                    href="https://app.cub.bi/#/support:phone"
                                    className={
                                      'block py-2 px-4 text-sm text-gray-700'
                                    }
                                  >
                                    Call Us
                                  </a>
                                </Menu.Item>
                                <Menu.Item key="email">
                                  <a
                                    href="https://app.cub.bi/#/support:email"
                                    target="__blank"
                                    className={
                                      'block py-2 px-4 text-sm text-gray-700'
                                    }
                                  >
                                    Email Us
                                  </a>
                                </Menu.Item>
                                <Menu.Item key="support">
                                  <a
                                    href="https://support.cub.bi/help"
                                    target="__blank"
                                    className={
                                      'block py-2 px-4 text-sm text-gray-700'
                                    }
                                  >
                                    Help Center
                                  </a>
                                </Menu.Item>
                              </Menu.Items>
                            </Menu>
                            <Menu as="div" className="relative ml-3">
                              <div>
                                <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                  <FontAwesomeIcon
                                    icon={faBars}
                                    size="lg"
                                    className="text-gray-400"
                                  />
                                </Menu.Button>
                              </div>
                              <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <Menu.Item>
                                  {({ active }) => (
                                    <div
                                      onClick={() => {
                                        track('your_account_clicked');
                                        navigate('/account');
                                      }}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                      )}
                                    >
                                      Your Account
                                    </div>
                                  )}
                                </Menu.Item>

                                <Menu.Item key="logout">
                                  <div
                                    onClick={handleLogout}
                                    className="block py-2 px-4 text-sm text-gray-700"
                                  >
                                    Logout
                                  </div>
                                </Menu.Item>
                              </Menu.Items>
                            </Menu>
                          </div>
                        </div>
                        <div className="-mr-2 flex md:hidden">
                          <div className="relative z-10 flex items-center ">
                            <Menu as="div" className="pr-2">
                              <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <FontAwesomeIcon
                                  icon={faHeadset}
                                  size="lg"
                                  className="text-gray-400"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-100 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-100 scale-95"
                              >
                                <Menu.Items className="absolute right-0 mt-2 w-28 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <Menu.Item key="call">
                                    <a
                                      target="__blank"
                                      href="https://app.cub.bi/#/support:phone"
                                      className={
                                        'block py-2 px-4 text-sm text-gray-700'
                                      }
                                    >
                                      Call Us
                                    </a>
                                  </Menu.Item>
                                  <Menu.Item key="email">
                                    <a
                                      href="https://app.cub.bi/#/support:email"
                                      target="__blank"
                                      className={
                                        'block py-2 px-4 text-sm text-gray-700'
                                      }
                                    >
                                      Email Us
                                    </a>
                                  </Menu.Item>
                                  <Menu.Item key="support">
                                    <a
                                      href="https://support.cub.bi/help"
                                      target="__blank"
                                      className={
                                        'block py-2 px-4 text-sm text-gray-700'
                                      }
                                    >
                                      Help Center
                                    </a>
                                  </Menu.Item>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                          <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            {open ? (
                              <XIcon
                                className="block h-6 w-6"
                                aria-hidden="true"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faBars}
                                size="lg"
                                aria-hidden="true"
                              />
                            )}
                          </Disclosure.Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="border-b border-gray-700 md:hidden">
                    <div className="space-y-1 px-2 py-3 sm:px-3">
                      {navigation.map((item, i) => (
                        <Disclosure.Button
                          key={i}
                          as="a"
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'block rounded-md px-3 py-2 text-base font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                    <div className="border-t border-gray-700 pt-4 pb-3">
                      {me && (
                        <div className="flex items-center px-5">
                          <span className="mr-2.5 inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                            <span className="font-medium leading-none text-white">
                              {`${me.firstName.charAt(0)}${
                                me.lastName ? me.lastName.charAt(0) : ''
                              }`}
                            </span>
                          </span>
                          <div>
                            <div className="mb-1.5 text-base font-medium leading-none text-white">
                              {`${me.firstName} ${me.lastName}`}
                            </div>
                            <div className="text-sm font-medium leading-none text-gray-400">
                              {me.email}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="mt-3 space-y-1 px-2">
                        <div
                          key="account"
                          onClick={() => navigate('/account')}
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                          Your Account
                        </div>

                        <div
                          key="logout"
                          onClick={handleLogout}
                          className="block cursor-pointer rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                          Logout
                        </div>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <header className="py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-semibold text-white">
                  Deliveries
                </h1>
                <div className="flex w-full flex-row">
                  <div className="mt-10 w-1/2 pr-2">
                    <button
                      className="flex w-full justify-center rounded-lg border-2 border-[#E4F3FF] bg-[#E4F3FF] py-3 text-sm font-light text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={handleNavigation}
                    >
                      <div className="content-center text-sm text-base leading-4">
                        <div>
                          <img src={calendar} className="h-4 w-4"></img>
                        </div>
                      </div>
                      <div className="pl-1 font-medium">Pre-order</div>
                    </button>
                  </div>
                  <div className="mt-10 w-1/2 pl-2">
                    {/*TODO ADD LINK TO NEW NATIVE APP */}
                    <a
                      className="relative z-50 flex w-full justify-center rounded-lg border-2 border-white bg-gray-800 px-4 py-3 text-sm font-light text-white opacity-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      href={handleLink()}
                    >
                      {/*extra div with hidden text to fake centering */}
                      <div className="content-center pr-2 text-sm">
                        <FontAwesomeIcon
                          icon={faUtensils}
                          className="text-base leading-4"
                        />
                      </div>
                      Grab 'n Go
                    </a>
                    <div className="align-center flex justify-center pt-1 text-xs text-[#FAF09B]">
                      Available in the NEW app!
                    </div>
                    <PrestockTooltipModal
                      open={prestockOpen}
                      setOpen={setPrestockOpen}
                      me={me}
                    />
                  </div>
                </div>
                <nav
                  className="flex space-x-8 overflow-auto bg-gray-800 px-4"
                  aria-label="Tabs"
                >
                  {Object.values(DeliveryTab).map((title, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => handleChangeTab(title)}
                        className={classNames(
                          title === selectedTab
                            ? 'border-white text-white'
                            : 'text-gray border-transparent text-gray-500 hover:border-gray-500 hover:text-gray-500',
                          'group text-md mb-2 inline-flex cursor-pointer items-center border-b-2 pb-4 pt-9'
                        )}
                      >
                        <div>{title}</div>
                      </div>
                    );
                  })}
                </nav>
              </div>
            </header>
          </div>

          <main className="-mt-40 pb-4">
            <div className="overflow-scroll-y mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
              <div className="rounded-lg">
                <div className="grid gap-y-4">
                  {selectedTab === DeliveryTab.Past &&
                    pastOrders &&
                    pastGroupedByDates &&
                    pastGroupedByDates[0] &&
                    pastGroupedByDates.map((bookings, i) => {
                      const date = moment(bookings[0].startAt);
                      const length = bookings.length;
                      return (
                        <Fragment key={i}>
                          <div className="rounded-lg bg-white shadow">
                            <div className="flex flex-col justify-center py-4">
                              <div className="flex justify-center text-xl text-gray-900">
                                {date.format('dddd MMM D')}
                              </div>
                              <div className="text-md flex justify-center text-gray-400">
                                {length > 1
                                  ? length + ' Deliveries'
                                  : 1 + ' Delivery'}
                              </div>
                            </div>
                            <DeliveryResults
                              bookings={groupByDateFridge(
                                bookings ? bookings : []
                              )}
                              handleNavigation={handleNavigation}
                              today={false}
                              past={true}
                              totalBookings={pastOrders ? pastOrders : []}
                            />
                          </div>
                        </Fragment>
                      );
                    })}
                  {selectedTab === DeliveryTab.Past &&
                    pastOrders &&
                    !pastOrders[0] && (
                      <>
                        <div className="rounded-lg bg-white shadow">
                          <DeliveryResults
                            bookings={[]}
                            handleNavigation={handleNavigation}
                            today={false}
                            past={true}
                            totalBookings={[]}
                          />
                        </div>
                      </>
                    )}
                  {selectedTab === DeliveryTab.Today && todayOrders && (
                    <div className="rounded-lg bg-white shadow ">
                      {todayOrders[0] && (
                        <div className="flex flex-col justify-center py-8">
                          <div className="flex justify-center text-xl text-gray-900">
                            {moment().format('dddd MMM D')}
                          </div>
                          <div className="text-md flex justify-center text-gray-400">
                            {todaysGroupedFridges.length > 1
                              ? todaysGroupedFridges.length + ' Deliveries'
                              : 1 + ' Delivery'}
                          </div>
                        </div>
                      )}
                      <DeliveryResults
                        bookings={groupByFridge(todayOrders ? todayOrders : [])}
                        handleNavigation={handleNavigation}
                        today={true}
                        past={false}
                        totalBookings={todayOrders}
                      />
                    </div>
                  )}
                  {selectedTab === DeliveryTab.Upcoming && upcomingOrders && (
                    <>
                      {upcomingOrders[0] &&
                        upcomingGroupedByDates &&
                        upcomingGroupedByDates[0] &&
                        upcomingGroupedByDates.map((bookings) => {
                          const date = moment(
                            bookings[0].startAt.substring(0, 10)
                          );
                          const length = bookings.length;
                          return (
                            <>
                              <div className="rounded-lg bg-white shadow">
                                <div className="flex flex-col justify-center py-4">
                                  <div className="flex justify-center text-xl text-gray-900">
                                    {date.format('dddd MMM D')}
                                  </div>
                                  <div className="text-md flex justify-center text-gray-400">
                                    {length > 1
                                      ? length + ' Deliveries'
                                      : 1 + ' Delivery'}
                                  </div>
                                </div>
                                <DeliveryResults
                                  bookings={groupByDateFridge(
                                    bookings ? bookings : []
                                  )}
                                  handleNavigation={handleNavigation}
                                  past={false}
                                  totalBookings={upcomingOrders}
                                  today={false}
                                />
                              </div>
                            </>
                          );
                        })}
                    </>
                  )}
                  {selectedTab === DeliveryTab.Upcoming &&
                    upcomingOrders &&
                    !upcomingOrders[0] && (
                      <>
                        <div className="rounded-lg bg-white shadow">
                          <DeliveryResults
                            bookings={[]}
                            today={false}
                            past={true}
                            handleNavigation={handleNavigation}
                            totalBookings={[]}
                          />
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
